@mixin post-card-mobile-big {
  .imageWrapper {
    width: 96px;
    min-width: 96px;
  }
}

@mixin post-card-mobile {
  .postCard,
  & {
    height: 84px;
    flex-direction: row;
    box-shadow: $global-shadow-card-small;
  }

  .body {
    padding: 12px 16px;
  }

  .label {
    font-size: 12px;
    background-color: transparent;
    padding: 0;
    color: $brand-primary;
    position: relative;
    top: auto;
  }

  .extraInfo {
    position: absolute;
    margin-bottom: 0;
    right: 0;
    top: 0;
    font-size: 12px;
    padding: inherit;

    .icon {
      > span {
        margin-right: 4px;
        height: 12px !important;
        width: 12px !important;
      }
    }

    .people {
      margin-right: 8px;
    }
  }

  .title {
    font-size: 14px;
    @include truncate-text(20, 1);
  }

  .description {
    font-size: 13px;
    margin-top: 4px;
    @include truncate-text(40, 2);
  }

  .imageWrapper {
    width: 120px;
    min-width: 120px;
    height: 100%;
  }

  .link {
    display: none;
  }
}
