@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.faqTab {
  h3 {
    margin-bottom: 24px;
  }
}

.panel {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
