@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.filterMenu {
  background: white;
  border: $global-border-style;
  padding: 16px;
  font-size: 16px;
  color: $global-subtext-color;
  list-style: none !important;
  border-radius: $global-border-radius;
  @media (min-width: $breakpoint-medium) {
    padding: 24px;
  }
  .title > * {
    margin-bottom: 16px;
  }
  .list {
    margin: 0;
    padding: 0;
    list-style: none !important;
  }
  .download {
    margin-top: 20px;
  }
}
