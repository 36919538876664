@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.follow-us-buttons {
  display: inline-flex;
  margin-bottom: 2px;

  > a.icon {
    &:hover {
      svg path {
        fill: $button-primary-background-hover;
      }
    }
  }

  > a {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    svg circle {
      fill: $button-primary-background;
    }

    &:hover {
      svg circle {
        fill: $button-primary-background-hover;
      }
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
