@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productSearchResults {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
}

.product {
  color: $global-bodytext-color;
  font-weight: 500;
  font-size: 14px;

  .productCapacity {
    color: $brand-secondary;
    margin-left: 4px;
  }
}

.cta {
  margin-top: 16px;
}
