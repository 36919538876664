@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.cta {
  justify-content: center;
  display: flex;
}

.container {
  width: 100%;
  display: flex;
  position: relative;
}

.align-center {
  &,
  .buttonList {
    justify-content: center;
    text-align: center;
    @media (max-width: $breakpoint-small) {
      text-align: left;
    }
  }
}

.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

  @media (max-width: 639px) {
    background-position-x: 60%;
  }

  &.gradient {
    background: linear-gradient(
        90deg,
        rgba(245, 250, 250, 0.9) 0%,
        rgba(245, 250, 250, 0.9) 58.74%,
        rgba(245, 250, 250, 9e-5) 100%
      )
      top left / 40% 100% no-repeat;
    @media (max-width: 959px) {
      background: linear-gradient(
          90deg,
          rgba(245, 250, 250, 0.9) 0%,
          rgba(245, 250, 250, 0.9) 58.74%,
          rgba(245, 250, 250, 9e-5) 100%
        )
        top left / 60% 100% no-repeat;
    }

    @media (max-width: 639px) {
      background: rgba(245, 250, 250, 0.4);
    }
  }
}

.title {
  margin-bottom: 16px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $breakpoint-small) {
    max-width: 70%;
  }
  @media (min-width: $breakpoint-medium) {
    max-width: 60%;
  }
}

.description {
  margin-bottom: 24px;
}

.buttonList {
  margin: 0 0 -16px;
  padding: 0;
  list-style: none;
  z-index: 99;

  .buttonItem {
    padding: 0 0 16px;
  }

  @media (min-width: $breakpoint-small) {
    display: flex;
    align-items: center;
    margin: 0 -8px;
    .buttonItem {
      padding: 0 8px;
    }
  }
}

.decor {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  text-align: center;
  svg {
    width: 100%;
  }
  @media (max-width: $breakpoint-small-max) {
    display: none;
  }
  @media (min-width: $breakpoint-large) {
    svg {
      width: 100%;
    }
  }
}
