@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.sidebarBlock {
  display: flex;
  flex-direction: column;
  border-radius: $global-border-radius;
  border: $global-border-style;
  background: white;
  padding: 24px;

  @media (max-width: $breakpoint-small-max) {
    padding: 16px;
  }
}

.description {
  font-size: 14px;
  margin-top: 16px;
  line-height: 22px;
}

.content {
  margin-top: 16px;

  > :not(:last-child) {
    margin-bottom: 8px;
  }

  > button,
  a {
    text-align: left;
  }
}

.items {
  margin-top: 16px;
  ul:not([class]) {
    margin-bottom: 0;

    & > li {
      padding-left: 22px;

      &:before {
        top: calc(50% - 3px);
      }
    }
  }

  .item {
    display: flex;
    > button,
    a {
      text-align: left;
    }
  }
}

.property {
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .value {
    margin-left: 6px;
  }
}
