@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.navBarItems {
  ul:not([class]) > li::before {
    content: none;
  }

  ul:not([class]) > li:nth-child(n + 2) {
    margin-top: 0px;
  }

  .topMenu {
    > li {
      padding: 16px;
    }
  }

  li,
  a {
    text-decoration: none;
    font-size: 16px;
    color: $global-bodytext-color;
    display: flex;
    line-height: 1;
    align-items: center;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  li {
    display: block;
    float: left;
    padding: 0px;
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  li:hover,
  li:focus-within {
    cursor: pointer;

    > a {
      color: $brand-primary;
      > .image {
        svg path {
          fill: $brand-primary;
        }
      }
    }

    > .moreIcon {
      svg path {
        fill: $brand-primary;
      }
    }
  }

  li:focus-within a {
    outline: none;
  }

  ul li ul {
    border: $global-border-style;
    visibility: hidden;
    opacity: 0;
    min-width: 240px;
    max-width: 300px;
    position: absolute;
    left: 0;
    display: none;
    top: 40px;
    background: white;
    border-radius: 16px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
    z-index: 100;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 32px);
      height: calc(100% + 40px);
      left: -16px;
    }
  }

  ul li:hover > ul,
  ul li:focus-within > ul,
  ul li ul:hover,
  ul li ul:focus {
    visibility: visible;
    opacity: 1;
    display: block;
    color: $brand-primary;
    height: auto;
  }

  ul li ul li {
    clear: both;
    width: 100%;

    padding: 12px 16px 12px 16px;

    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }

  ul li ul li ul {
    padding-top: 0;
    top: 0;
    left: calc(100% + 8px);
    display: none;
    margin-top: 0px;
    height: auto;

    &:before {
      top: -16px;
      left: -8px;
      width: calc(100% + 16px);
      height: calc(100% + 16px + 16px);
    }
  }
}

.moreIcon {
  display: flex;
  margin-left: 8px;

  svg path {
    fill: $global-bodytext-color;
  }
}
