@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
$vertical-spacing: 12px;
$horizontal-spacing: 24px;
$vertical-spacing-mobile: 8px;
$horizontal-spacing-mobile: 16px;
$card-padding: $vertical-spacing $horizontal-spacing;
$title-margin: -#{$vertical-spacing} -#{$horizontal-spacing};
$card-padding-mobile: $vertical-spacing-mobile $horizontal-spacing-mobile;
$title-margin-mobile: -#{$vertical-spacing-mobile} -#{$horizontal-spacing-mobile};

.expansionPanel {
  margin-top: 8px;
  cursor: pointer;
  position: relative;
  padding: $card-padding;
  background: white;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-small;

  &:hover {
    transform: scale(1.01);
  }

  @include focus-custom();

  @media (max-width: $breakpoint-xsmall-max) {
    padding: $card-padding-mobile;
  }

  &.nonClickable {
    cursor: inherit;
    &:hover {
      transform: scale(1);
    }
  }
}

.content {
  position: relative;
  font-size: 16px;
  cursor: initial;
  overflow: hidden;
  transition: height 0.15s ease-out;
}

.productHeaderText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 16px;
}

.title {
  margin: $title-margin;
  padding: $card-padding;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 50%;
    margin-left: 8px;
    margin-right: $horizontal-spacing;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url("../../../../assets/icons/plus.svg");
  }

  &.isOpen:after {
    content: "";
    background-image: url("../../../../assets/icons/minus.svg");
  }

  @media (max-width: $breakpoint-xsmall-max) {
    padding: $card-padding-mobile;
    margin: $title-margin-mobile;

    &:after {
      margin-right: $horizontal-spacing-mobile;
    }
  }
}

.imgWrapper {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.productheader {
  display: flex;
}

.description {
  font-size: 12px;
  color: $tint-grey-49;
}

.description {
  color: $tint-grey-49;
  font-size: 12px;
}

.measuringWrapper {
  padding-top: $vertical-spacing;
  @media (max-width: $breakpoint-xsmall-max) {
    padding-top: $vertical-spacing-mobile;
  }
}
