@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
$language-white: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0id2hpdGUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCA2LjVDMCAyLjkxMDE1IDIuOTEwMTUgMCA2LjUgMEMxMC4wODk5IDAgMTMgMi45MTAxNSAxMyA2LjVDMTMgMTAuMDg5OSAxMC4wODk5IDEzIDYuNSAxM0MyLjkxMDE1IDEzIDAgMTAuMDg5OSAwIDYuNVpNMi44NTc2NSAxMC4zMjQzQzMuODA1MjMgMTEuMjI3IDUuMDg3ODkgMTEuNzgxMiA2LjUgMTEuNzgxMkM5LjQxNjc1IDExLjc4MTIgMTEuNzgxMiA5LjQxNjc1IDExLjc4MTIgNi41QzExLjc4MTIgNS40MDE5MiAxMS40NDYxIDQuMzgyMTIgMTAuODcyNiAzLjUzNzI5QzkuOTEwNDUgMy45IDguMjU4NzggMy45IDguMTI1IDMuOUM3LjU1NjI1IDMuOSA2LjkwNjI1IDMuNDkzNzUgNi41IDIuODQzNzVDNi4zMjQ2OSAyLjYxIDYuMDY1MzIgMS44NzE5MSA1Ljg3MzA0IDEuMzI0NzJMNS44NzMwMyAxLjMyNDcxTDUuODczMDMgMS4zMjQ2OUM1Ljg2NTE0IDEuMzAyMjUgNS44NTczNyAxLjI4MDEzIDUuODQ5NzIgMS4yNTgzOUM0Ljg0MTEgMS4zODIyMyAzLjkyMDQ0IDEuNzkwNDQgMy4xNzAzOCAyLjQwMDM2QzMuODQzNzUgMy4xNjU3NSA0Ljg2NzI5IDQuNDA3OTIgNC4zODc1IDQuOTU2MjVDNC4xNzE3MSA1LjI0Mzk3IDMuNzQ4OTUgNS40NTIxIDMuMzQ0NzQgNS42NTEwOUMyLjgzNTc5IDUuOTAxNjQgMi4zNTYyNSA2LjEzNzcyIDIuMzU2MjUgNi41QzIuMzU2MjUgNi44NDM1NCAyLjY3OTA0IDcuMjI3NDMgMy4wMTE4NSA3LjYyMzI0QzMuMTUxMzEgNy43ODkxIDMuMjkyNTMgNy45NTcwNSAzLjQxMjUgOC4xMjVDMy44NTMxNyA4LjcxMjU1IDQuMDI4MjggOS41NjU2NiAyLjg1NzY1IDEwLjMyNDNaTTguMjg3NDkgNS40NDM3MUM3LjMxMjQ5IDUuNDQzNzEgNi40MTg3NCA2LjA5MzcxIDYuNDk5OTkgNi45ODc0NkM2LjU5NTg2IDcuNzg2NDIgNi41NTM0NiA4LjM4NDI1IDYuNTIxMTIgOC44NDAyOUM2LjQ3MTI0IDkuNTQzNjggNi40NDUyOCA5LjkwOTc1IDYuOTg3NDkgMTAuMTU2MkM4LjA0Mzc0IDEwLjU2MjUgMTAuMTU2MiA4LjYxMjQ2IDEwLjA3NSA2Ljk4NzQ2QzEwLjA3NSA2LjA5MzcxIDkuMzQzNzQgNS40NDM3MSA4LjI4NzQ5IDUuNDQzNzFaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K";
$language-black: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDhDMCAzLjU4MTcyIDMuNTgxNzIgMCA4IDBDMTIuNDE4MyAwIDE2IDMuNTgxNzIgMTYgOEMxNiAxMi40MTgzIDEyLjQxODMgMTYgOCAxNkMzLjU4MTcyIDE2IDAgMTIuNDE4MyAwIDhaTTMuNTE3MTEgMTIuNzA2OEM0LjY4MzM1IDEzLjgxNzkgNi4yNjIwMiAxNC41IDggMTQuNUMxMS41ODk5IDE0LjUgMTQuNSAxMS41ODk5IDE0LjUgOEMxNC41IDYuNjQ4NTIgMTQuMDg3NSA1LjM5MzM4IDEzLjM4MTYgNC4zNTM1OUMxMi4xOTc1IDQuOCAxMC4xNjQ3IDQuOCAxMCA0LjhDOS4zIDQuOCA4LjUgNC4zIDggMy41QzcuNzg0MjMgMy4yMTIzMSA3LjQ2NTAxIDIuMzAzODkgNy4yMjgzNSAxLjYzMDQyTDcuMjI4MzUgMS42MzA0MUw3LjIyODM0IDEuNjMwMzhDNy4yMTg2NCAxLjYwMjc3IDcuMjA5MDcgMS41NzU1NSA3LjE5OTY2IDEuNTQ4NzlDNS45NTgyOCAxLjcwMTIxIDQuODI1MTYgMi4yMDM2MiAzLjkwMjAxIDIuOTU0MjlDNC43MzA3NyAzLjg5NjMxIDUuOTkwNTEgNS40MjUxMyA1LjQgNi4xQzUuMTM0NDEgNi40NTQxMiA0LjYxNDA5IDYuNzEwMjcgNC4xMTY2IDYuOTU1MTlDMy40OTAyMSA3LjI2MzU2IDIuOSA3LjU1NDEyIDIuOSA4QzIuOSA4LjQyMjgyIDMuMjk3MjggOC44OTUzIDMuNzA2ODkgOS4zODI0NUMzLjg3ODU0IDkuNTg2NTggNC4wNTIzNSA5Ljc5MzI5IDQuMiAxMEM0Ljc0MjM2IDEwLjcyMzEgNC45NTc4OCAxMS43NzMxIDMuNTE3MTEgMTIuNzA2OFpNMTAuMiA2LjY5OTk1QzguOTk5OTggNi42OTk5NSA3Ljg5OTk4IDcuNDk5OTUgNy45OTk5OCA4LjU5OTk1QzguMTE3OTggOS41ODMyOCA4LjA2NTggMTAuMzE5MSA4LjAyNiAxMC44ODA0QzcuOTY0NiAxMS43NDYxIDcuOTMyNjUgMTIuMTk2NiA4LjU5OTk5IDEyLjVDOS44OTk5OCAxMyAxMi41IDEwLjYgMTIuNCA4LjU5OTk1QzEyLjQgNy40OTk5NSAxMS41IDYuNjk5OTUgMTAuMiA2LjY5OTk1WiIgZmlsbD0iIzM3MzczNyIvPgo8L3N2Zz4K";

.black {
  select {
    color: $global-bodytext-color !important;
  }

  > div {
    display: flex;
    align-items: center;
  }

  > ::before {
    width: 16px !important;
    height: 16px !important;
    background: url($language-black) no-repeat !important;
  }
}

.white {
  select {
    color: white !important;
  }

  > div {
    display: flex;
    align-items: center;
  }

  > ::before {
    width: 16px !important;
    height: 16px !important;
    background: url($language-white) no-repeat !important;
  }
}
