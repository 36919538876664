@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.businessTab {
  h3 {
    margin-bottom: 16px;
  }
}

.downloads {
  margin-top: 40px;
}

.links {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 4px;
  }
}
