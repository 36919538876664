// Brand colors
$brand-primary: #008e90;
$brand-primary-dark: #00797a;
$brand-primary-darkest: #006566;
$brand-primary-light: #80c6c7;
$brand-primary-lightest: #ddeded;
$brand-secondary: #ca575c;
$brand-secondary-dark: #b64045;
$brand-secondary-darkest: #943439;
$brand-secondary-light: #faf0ee;

// Grey tints
$tint-grey-3: #f5fafa;
$tint-grey-5: #eef6f6;
$tint-grey-8: #eceaea;
$tint-grey-37: #a3a3a3;
$tint-grey-49: #828282;
$tint-grey-51: #7c7c7c;
$tint-grey-76: #3e3e3e;
$tint-grey-78: #373737;

// Blue tints
$tint-blue-51: #818383;

// Functional
$error: #ca575c;

//
// Breakpoints
//
$breakpoint-xsmall: 480px !default;
$breakpoint-small: 640px !default;
$breakpoint-small-medium: 768px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;

$breakpoint-xsmall-max: ($breakpoint-small - 1) !default;
$breakpoint-small-max: ($breakpoint-medium - 1) !default;
$breakpoint-small-medium-max: ($breakpoint-small-medium - 1) !default;
$breakpoint-medium-max: ($breakpoint-large - 1) !default;
$breakpoint-large-max: ($breakpoint-xlarge - 1) !default;

//
// Typography
//

$global-font-family: "Ubuntu", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
  Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$global-font-family-secondary: "Asap Condensed", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$global-font-size: 16px;
$global-line-height: 1.44;

// Font colors
$global-bodytext-color: $tint-grey-78;
$global-small-title-color: $tint-grey-78;
$global-title-color: $brand-secondary;
$global-subtext-color: $tint-grey-49;

//
// Borders
//
$global-border-width: 1px;
$global-border-color: $tint-grey-8;
$global-border-style: 1px solid $tint-grey-8;
$global-border-radius: 14px;

//
// Spacings
//
$global-spacing-xsmall: 8px;
$global-spacing-small: 16px;
$global-spacing-medium: 24px;
$global-spacing-large: 32px;
$global-spacing-xlarge: 40px;

// Used in grid, column, container, align, card, padding
$global-gutter: 24px;
$global-small-gutter: 16px;
$global-medium-gutter: 32px;
$global-large-gutter: 40px;

//
// Container
//
$container-max-width: 1160px !default;
$container-small-max-width: 740px !default;
$container-medium-max-width: 860px !default;
$container-large-max-width: 1600px !default;

//
// Buttons
//
$button-primary-background: $brand-primary;
$button-primary-background-hover: $brand-primary-dark;

$button-secondary-background: white;
$button-secondary-background-hover: $tint-grey-3;

$button-tertiary-background: $brand-secondary;
$button-tertiary-background-hover: $brand-secondary-dark;

$menu-item-color: $tint-grey-78;

//
// Shadows
//
$global-shadow-card-small: 0px 4px 8px rgba(0, 0, 0, 0.06);
$global-shadow-card-big: 0px 6px 16px rgba(0, 0, 0, 0.06);

//
// Mixins
//

@mixin truncate-text($max-height, $amount-of-lines) {
  max-height: #{$max-height}px;
  line-height: #{$max-height / $amount-of-lines}px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $amount-of-lines;
  -webkit-box-orient: vertical;
}

@mixin body-text-alignment() {
  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}

@mixin body-text-size() {
  &,
  &.full {
    width: 100%;
  }
  &.large {
    width: calc(#{$container-max-width} * 0.8);
  }

  &.medium {
    width: calc(#{$container-max-width} * 0.5);
  }

  &.small {
    width: calc(#{$container-max-width} * 0.3);
  }
}

@mixin button-as-link() {
  @include button-reset;
  text-align: left;
  color: $brand-primary;
  &:hover {
    color: $brand-primary-dark;
  }
  &:active {
    color: $brand-primary-darkest;
  }

  @include focus-custom();
}

@mixin button-reset() {
  font-size: 16px;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  font-family: $global-font-family;
  cursor: pointer;

  &:active {
    color: $global-bodytext-color;
  }
}

@mixin safari-border-radius-bug() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@mixin focus-reset() {
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
  }

  &:-moz-focusring {
    outline: auto;
  }
}

@mixin focus-custom() {
  @include focus-reset();

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px rgba($brand-primary, 0.4);
  }

  &:-moz-focusring {
    outline: none;
    box-shadow: 0 0 0 3px rgba($brand-primary, 0.4);
  }
}
