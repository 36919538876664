@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.featuredProducts {
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    @media (min-width: $breakpoint-small) {
      margin-bottom: 40px;
    }
    @media (min-width: $breakpoint-medium) {
      margin-bottom: 56px;
    }
  }

  .buttonTop {
    display: block;
    z-index: 99;
    @media (max-width: $breakpoint-xsmall-max) {
      display: none;
    }
  }

  .slide {
    width: 100%;
    height: 100%;
  }

  .buttonBottom {
    display: none;
    button {
      width: 100%;
    }
    @media (max-width: $breakpoint-xsmall-max) {
      display: initial;
    }
  }
}

.small {
  .header {
    margin-bottom: 24px;
  }
}
