@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.listDetailItem {
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: $global-border-style;

  &.light {
    .label {
      color: $global-subtext-color;
    }
  }

  &.dark {
    .label {
      color: $global-bodytext-color;
    }
  }

  &.colored {
    .label,
    .value {
      color: $brand-primary;
    }
  }

  .value {
    margin-left: auto;
    color: $global-bodytext-color;
  }
}

a.listDetailItem {
  &:hover {
    .label,
    .value {
      color: $brand-primary;
    }

    .label {
      background-image: linear-gradient(to right, $brand-primary 33%, rgba($brand-primary, 0) 0%);
    }

    &.colored {
      .label {
        background-image: linear-gradient(to right, $brand-primary 100%, rgba($brand-primary, 0) 0%);
      }
    }
  }

  .label {
    background-image: linear-gradient(to right, $global-bodytext-color 33%, rgba($global-bodytext-color, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
  }

  &.colored {
    .label {
      background-image: linear-gradient(to right, $brand-primary 33%, rgba($brand-primary, 0) 0%);
    }
  }
}
