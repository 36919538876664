@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.bodyText {
  color: $global-bodytext-color;

  table {
    max-width: 100%;
  }

  img {
    border-radius: $global-border-radius;
  }

  h1,
  h2,
  h3 {
    margin: 0 0 16px 0;
    &:not(:first-child) {
      margin: 40px 0 16px 0;
    }
    + p {
      margin: 0;
    }
  }

  @media (max-width: $breakpoint-small-max) {
    h1,
    h2,
    h3 {
      margin: 0 0 8px 0;
      &:not(:first-child) {
        margin: 24px 0 8px 0;
      }
      + p {
        margin: 0;
      }
    }
  }

  p ~ ul,
  p ~ ol {
    margin-top: 8px;
  }

  a {
    color: $button-tertiary-background;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bodyText {
  display: flex;
  position: relative;

  .body-text-container {
    max-width: $container-max-width;
  }

  .body-text-container-outer {
    max-width: $container-max-width;
    margin: auto;
    display: flex;
  }

  @include body-text-alignment;
}

.aligner {
  max-width: 100%;

  iframe {
    max-width: 100%;
  }

  @include body-text-size;
}
