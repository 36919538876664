@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.itemOverview {
}

.filters {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: $breakpoint-xsmall-max) {
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
}

.filterItem {
  max-width: 260px;

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    max-width: 100%;
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
}

.pagination {
  margin-top: 56px;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-top: 32px;
  }
}

.notFound {
  display: flex;
  justify-content: center;
}
