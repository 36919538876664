@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.expansionPanel {
  margin-bottom: 32px;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-bottom: 24px;
  }
}

.title {
  margin-bottom: 24px;
  &.isCentered {
    text-align: center;
  }
}

.panelWrapper {
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
}
