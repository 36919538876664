@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.categoriesSearchResults {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
}

.category {
  display: flex;
  border-radius: $global-border-radius;
  height: 60px;
  box-shadow: $global-shadow-card-small;
  color: $global-bodytext-color;
  overflow: hidden;
  font-size: 14px;
  align-items: center;

  .text {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;
    .label {
      font-size: 12px;
      color: $brand-primary;
    }

    .title {
      @include truncate-text(56, 3);

      &.titleWithLabel {
        @include truncate-text(36, 2);
      }
    }
  }

  .imageWrapper {
    width: 60px;
    flex-shrink: 0;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .productCapacity {
    color: $brand-secondary;
    margin-left: 4px;
  }
}

.toggleButton {
  margin-top: 16px;
  display: flex;
  margin-left: auto;
}
