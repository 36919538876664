/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  min-height: 1px;

  box-sizing: border-box;
  height: inherit;

  @media (max-width: $breakpoint-xsmall-max) {
    padding: 0;
  }
  > div {
    width: 100%;
    height: inherit;

    &:focus {
      outline: none;
    }
  }
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: flex;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .dot-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }
  li {
    display: inline-flex;
    margin: 0 3px;
    button {
      cursor: pointer;
      outline: none;
      padding: 0;
      border: 0;
      margin: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      text-indent: -9999px;
      overflow: hidden;
      border: 1.5px solid $tint-grey-51;
      background: transparent;
      transform: scale(0);
      transition: all 0.1s ease-in-out;
      &:hover {
        background: $tint-grey-78;
      }
    }
    &.slick-active {
      button {
        transform: scale(1);
        background: $tint-grey-78;
        border: 1.5px solid transparent;
      }
    }
    &.big {
      button {
        transform: scale(1);
      }
    }
    &.small {
      button {
        transform: scale(0.8);
      }
    }
  }
}

.slick-arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -24px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  background: $brand-secondary;
  color: white;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
  &.slick-disabled {
    opacity: 0;
    pointer-events: initial;
    cursor: default;
    // display: none;
  }
  @media (max-width: $breakpoint-small-max) {
    width: 37px;
    height: 37px;
    left: 0px;
  }
  &:before {
    display: none;
  }
  &.slick-next {
    left: auto;
    right: -24px;
    @media (max-width: $breakpoint-small-max) {
      right: 0px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: $brand-secondary-dark;
  }

  @include focus-custom();
}

.featuredProductsSlider {
  @media (max-width: $breakpoint-xsmall-max) {
    .slick-slide {
      flex-direction: column;
      > div {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    .slick-arrow {
      top: auto;
      bottom: -32px;
    }
    .slick-dots {
      margin: 32px 0px;
    }
  }

  .slick-list {
    margin: -20px -8px;
    @media (max-width: $breakpoint-xsmall-max) {
      margin: -10px -4px;
    }
  }
  .slick-slide {
    margin: 0 8px;
    @media (max-width: $breakpoint-xsmall-max) {
      margin: 0 4px;
    }
  }

  .slick-track {
    padding: 20px 0;
    @media (max-width: $breakpoint-xsmall-max) {
      padding: 10px 0;
    }
  }
}

.instagramSlider {
  .slick-list {
    margin: 0 -20px;
    @media (max-width: $breakpoint-xsmall-max) {
      margin: 0 -8px;
    }
  }
  .slick-slide {
    margin: 0 20px;
    @media (max-width: $breakpoint-xsmall-max) {
      margin: 0 8px;
    }
  }

  .slick-track {
    padding: 40px 0;
    @media (max-width: $breakpoint-xsmall-max) {
      padding: 10px 0;
    }
  }
}

.productLineSlider {
  .slick-arrow.slick-disabled {
    pointer-events: initial;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    .slick-slide {
      flex-direction: column;
      > div {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    .slick-arrow {
      top: auto;
      bottom: -32px;
    }
    .slick-dots {
      margin: 32px 0px;
    }
  }

  .slick-list {
    margin: -20px -8px;
    @media (max-width: $breakpoint-xsmall-max) {
      margin: -10px -4px;
    }
  }
  .slick-slide {
    margin: 0 8px;
    @media (max-width: $breakpoint-xsmall-max) {
      margin: 0 4px;
    }
  }

  .slick-track {
    padding: 20px 0;
    @media (max-width: $breakpoint-xsmall-max) {
      padding: 10px 0;
    }
  }
}

.gallerySlider {
  .slick-active {
    z-index: 1;
  }

  .slick-arrow.slick-next {
    left: auto;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    .slick-arrow {
      &.slick-next {
        left: auto;
        right: -8px;
      }
      &.slick-prev {
        right: auto;
        left: -18px;
      }
    }
  }
}

.fullScreenSliderThumbs {
  .slick-slide {
    margin: 0 8px;
  }

  .slick-arrow.slick-next {
    left: auto;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    .slick-slide {
      margin: 0 4px;
    }
    .slick-arrow {
      &.slick-next {
        left: auto;
        right: -8px;
      }
      &.slick-prev {
        right: auto;
        left: -18px;
      }
    }
  }
}

.fullScreenSlider,
.recipeHeaderSlider {
  .slick-active {
    z-index: 1;
  }
  &,
  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-arrow {
    &.slick-disabled {
      pointer-events: none;
    }
  }
}

.featuredPostsSlider {
  .slick-slide {
    margin: 0 16px;
  }
  .slick-track {
    padding: 20px 0;
  }

  .slick-arrow {
    @media (max-width: $breakpoint-small-max) {
      width: 45px;
      height: 45px;
      left: -18px;
      &.slick-next {
        left: auto;
        right: -18px;
      }
    }
  }

  @media (max-width: $breakpoint-small-medium-max) {
    .slick-arrow {
      width: 37px;
      height: 37px;
      left: 0;
      &.slick-next {
        left: auto;
        right: 0;
      }
    }
    .slick-slide {
      flex-direction: column;
      > div {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    .slick-arrow {
      top: auto;
      bottom: -32px;
    }
    .slick-dots {
      margin: 32px 0px;
    }
  }

  .slick-list {
    margin: -20px -8px;
    @media (max-width: $breakpoint-small-medium-max) {
      margin: -10px -4px;
    }
  }
  .slick-slide {
    margin: 0 8px;
    @media (max-width: $breakpoint-small-medium-max) {
      margin: 0 4px;
    }
  }

  .slick-track {
    padding: 20px 0;
    @media (max-width: $breakpoint-small-medium-max) {
      padding: 10px 0;
    }
  }
}
