@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productLineCard {
  height: 336px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.02);
    .title {
      color: $brand-secondary;
    }
    .image {
      transform: scale(1.05);
    }
  }
}

.imageWrapper {
  height: 312px;
  width: 100%;
  border-radius: $global-border-radius;
  overflow: hidden;
  box-shadow: $global-shadow-card-big;
  .image {
    transition: all 0.15s ease-in-out;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.linkCard {
  display: flex;
  align-items: center;
  background: white;
  padding: 16px;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-small;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 8px;

  @media (max-width: $breakpoint-xsmall-max) {
    position: relative;
  }

  .icon {
    margin-right: 8px;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .title {
    @include truncate-text(25, 1);
  }
}

@media (max-width: $breakpoint-xsmall-max) {
  .productLineCard {
    padding: 4px;
    height: 72px;
    flex-direction: row;
    border-radius: $global-border-radius;
    box-shadow: $global-shadow-card-small;
    background: white;
  }

  .imageWrapper {
    width: 88px;
    height: 100%;
    margin-right: 16px;
    box-shadow: none;
    border-radius: 12px 0px 0px 12px;
  }

  .linkCard {
    position: relative;
    background: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
}
