@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
@import "./post-card-component-responsive.module.scss";

.postCard {
  position: relative;
  flex-direction: column;
  display: flex;
  height: 414px;
  box-shadow: $global-shadow-card-big;
  border-radius: $global-border-radius;
  background: white;
  overflow: hidden;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.015);
    .link .linkIcon {
      transform: translateX(2px);
      svg path {
        fill: $brand-primary-dark;
      }
    }
  }

  @include focus-custom();
}

.smaller {
  height: 300px;

  .extraInfo {
    margin-bottom: 8px;
  }

  .body {
    padding: 24px 16px 16px 16px;
  }

  .title {
    font-size: 16px;
    @include truncate-text(46, 2);
  }
}

.title {
  color: $global-bodytext-color;

  &.hover {
    color: $brand-primary;
    cursor: pointer;
  }
}

.extraInfo {
  display: flex;
  font-size: 14px;
  color: $global-subtext-color;
  margin-bottom: 16px;

  .icon {
    display: flex;
    align-items: center;
    > span {
      margin-right: 8px;
    }

    svg path {
      fill: $global-subtext-color;
    }
  }

  .people {
    margin-right: 16px;
  }
}

.imageWrapper {
  height: 50%;
  min-height: 50%;
  width: 100%;
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px 24px 24px 24px;
  flex-grow: 1;
  align-items: flex-start;
}

.pagePost {
  .body {
    padding: 24px;
  }

  &.smaller {
    .body {
      padding: 16px;

      .title {
        @include truncate-text(40, 2);
      }
    }
  }
}
.title {
  font-size: 18px;
  color: $global-bodytext-color;
  @include truncate-text(70, 3);
}

.description {
  color: $global-subtext-color;
  font-size: 16px;
  margin-top: 8px;
  @include truncate-text(40, 2);
}

.label {
  border-radius: 100px;
  background-color: $brand-primary;
  font-size: 14px;
  position: absolute;
  color: white;
  padding: 2px 12px;
  top: -12px;
}

.link {
  margin-top: auto;
}

.link {
  display: flex;
  align-items: center;
  margin-top: auto;
  font-weight: 500;

  .linkText {
    margin-right: 8px;
  }

  .linkIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
    svg path {
      fill: $brand-primary;
    }
  }
}

@media (max-width: $breakpoint-xsmall-max) {
  @include post-card-mobile;
}

@media (max-width: 399px) {
  @include post-card-mobile-big;
}
.mobile {
  @include post-card-mobile;
}
