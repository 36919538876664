%heading {
  font-family: $global-font-family-secondary;
  margin: 0;
  color: $tint-grey-78;
  font-weight: 700;
}

%h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
  @media (max-width: $breakpoint-small-max) {
    font-size: 32px;
  }
}

%h2 {
  font-size: 30px;
  @media (max-width: $breakpoint-small-max) {
    font-size: 30px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
  }
}

%h3 {
  font-size: 24px;
  @media (max-width: $breakpoint-small-max) {
    font-size: 24px;
  }
}

%h4 {
  font-size: 18px;
  @media (max-width: $breakpoint-small-max) {
    font-size: 18px;
  }
}

%h5 {
  @media (max-width: $breakpoint-small-max) {
    font-size: 14px;
  }
  color: $brand-secondary;
}

%h6 {
  color: $tint-grey-76;
  font-weight: 500;
  margin-bottom: 4px;
  @media (max-width: $breakpoint-small-max) {
    font-size: 12px;
    margin-bottom: 2px;
  }
}
