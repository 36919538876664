@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.nav-bar {
  font-size: 16px;
  background: #fff;
  box-shadow: inset 0 -1px 0 0 $global-border-color;
  position: relative;
  z-index: 20;
  display: flex;
  border-bottom: $global-border-style;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-xsmall-max) {
    position: absolute !important;
    width: 100%;
  }

  .icon {
    display: none;
    z-index: 3;
    .border {
      height: 24px;
      width: 1px;
      margin-right: 16px;
      background-color: $global-border-color;
    }

    @media screen and (max-width: $breakpoint-xsmall-max) {
      margin-left: auto;
      position: absolute;
      right: 30px;
      top: 4px;
      display: flex;
      align-items: center;
    }
  }

  ul {
    height: 34px;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li {
      display: flex;

      img {
        margin-right: 5px;
      }

      @media screen and (max-width: $breakpoint-xsmall-max) {
        margin: 16px 0px;

        &:last-child {
          margin-top: 0;
        }
      }
    }
    @media screen and (max-width: $breakpoint-xsmall-max) {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      max-height: 0px;
      transition: max-height 100ms ease;
      overflow: hidden;
    }
  }

  &__top {
    font-size: 14px;
    line-height: 0.8;
    z-index: 3;
    max-height: 60px;
    color: white;
    background: $brand-primary;
    @media screen and (max-width: $breakpoint-xsmall-max) {
      max-height: 0px;
      transition: max-height 100ms ease;
      overflow: hidden;
    }
  }

  &__top-menu {
    justify-content: flex-end;
    z-index: 200;

    > div > ul {
      z-index: 201;
    }

    > div > ul > li > a > span {
      color: white;
      font-size: 14px;
    }

    > li {
      padding-left: 16px;
    }

    a {
      color: white;
      &:hover {
        color: rgba(white, 0.7);
        text-decoration: none;
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px rgba(white, 0.3);
      }
    }
  }

  .responsive-order {
    order: 0;
    background: white;
    z-index: 2;

    @media screen and (max-width: $breakpoint-xsmall-max) {
      order: -1;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__bottom-logo {
    position: relative;

    &__image {
      width: 91px;
      max-width: 40%;
      margin: 10px 16px -40px 0;
      @media screen and (max-width: $breakpoint-xsmall-max) {
        width: 60px;
        margin: 10px 16px -26px 0;
      }
    }
    &__text {
      width: 157px;
      max-width: 50%;
      @media screen and (max-width: $breakpoint-xsmall-max) {
        width: 125px;
      }
      @media screen and (max-width: 370px) {
        margin-left: -10px;
        width: 110px;
      }
    }
  }

  &__bottom-menu {
    @media screen and (max-width: $breakpoint-xsmall-max) {
      li:last-of-type {
        display: none;
      }
    }
    transition: margin-top 1ms linear !important;
    margin-top: 0px;
    &.responsive {
      margin-top: 30px !important;
    }

    a {
      color: $menu-item-color;
      &:hover {
        text-decoration: none;
        color: $brand-primary;
      }
    }
  }
}

.searchButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  cursor: pointer;

  svg path {
    fill: $menu-item-color;
  }

  &:hover {
    svg path {
      fill: $brand-primary;
    }
  }

  @media screen and (max-width: $breakpoint-xsmall-max) {
    padding: 8px;
    margin-right: 8px;
  }
}

.hamburgerMenu {
  z-index: 10;
}
