@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.faqOverview {
  position: relative;

  .sidebar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    .search {
      -webkit-order: 0;
      order: 0;
    }
    .blocks {
      margin-top: 16px;
    }
  }
}

.filterMenu,
.sidebarBlock {
  margin-bottom: 16px;

  @media (max-width: $breakpoint-small-max) {
    margin-bottom: 8px;
  }
}

.emptyState {
  margin-top: 12px;
  > div {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    button {
      @include button-as-link;
      margin-top: 4px;
    }
  }
  @media (max-width: $breakpoint-small-max) {
    margin-top: 0px;
  }
}

@media (max-width: $breakpoint-small-max) {
}

.mobileSelect {
  display: none;
  @media (max-width: $breakpoint-xsmall-max) {
    display: flex;
    margin: 8px 0px 24px 0px;
  }
}
