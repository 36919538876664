@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.overviewSidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filterGroups {
  margin-top: 24px;
}

.block {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  .title > * {
    margin-bottom: 8px;
  }
}

.sidebarLink {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.desktopFilters {
  display: flex;
}

.mobileFilters {
  display: none;
}

@media (max-width: 739px) {
  .overviewSidebar {
    margin-right: 0;
  }
  .block {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    .title > * {
      display: none;
    }
    .desktopFilters {
      display: none;
    }
  }

  .filterGroups {
    margin-top: 8px;
    height: 0;
    opacity: 0;
    pointer-events: none;

    &.showFilters {
      margin-bottom: 16px;
      height: auto;
      opacity: 1;
      pointer-events: all;
    }
  }

  .linkGroups {
    display: none;
  }

  .desktopFilters {
    display: none;
  }

  .mobileFilters {
    display: flex;
  }
}

.clearAll {
  margin-top: 4px;
}

.mobileFilterToggle {
  display: none;

  & > button {
    justify-content: space-between;

    span[class*="icon"] {
      // For some reason the icon is not aligned properly...
      margin-right: 2px;
    }
  }

  @media (max-width: 739px) {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
