@mixin tertiary-button {
  &.tertiary {
    background: transparent;
    padding: 0px;
    height: auto;
    border-radius: 0;
    color: $button-primary-background;

    svg path {
      fill: $button-primary-background;
    }

    &:hover {
      background-color: transparent;
      color: $button-primary-background-hover;

      svg path {
        fill: $button-primary-background-hover;
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
