@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
@mixin transition-transform() {
  transition: transform 0.2s ease-in-out;
}

.bigCard {
  position: relative;
  flex: 1 1 50%;
  height: 360px;
  cursor: pointer;
  @media (max-width: $breakpoint-medium - 1) {
    height: 200px;
  }

  &:hover {
    .image {
      transform: scale(1.04);
    }
  }
}

.imageWrapper {
  max-height: 333px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-big;
  @include safari-border-radius-bug();
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include transition-transform;
  }
}

.link {
  position: absolute;
  bottom: -16px;
  height: 88px;
  left: 0;
  right: 0;
  margin: 0 16px;

  > div {
    height: 100%;
    &:hover {
      transform: none;
    }
  }
  @media (max-width: $breakpoint-small-max) {
    height: 64px;
    bottom: -32px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    height: 56px;
    bottom: -24px;
  }

  @media (max-width: 480px) {
    bottom: 0px;
  }
}

@media (max-width: 480px) {
  .bigCard {
    display: flex;
    flex-direction: column;

    .link {
      height: 72px;
      margin: 0;
      margin-top: -24px;
      position: relative;
      width: 100%;
      padding: 0px;
      box-sizing: border-box;

      .image {
        display: block;
        margin-right: 16px;
        width: 100%;
        max-width: 88px;
        height: 56px;
        object-fit: cover;
        border-radius: $global-border-radius;
      }

      .linkText {
        margin-right: auto;
        width: 200px;
        font-size: 16px;
      }
    }
  }
}

.icon {
  display: flex;
  margin-right: 8px;
  svg path {
    fill: white;
  }
}
