@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.decorCurve {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 90;
  pointer-events: none;
  > svg {
    height: calc(100vw / 1920 * 128);
    max-height: 128px;
  }
  &.vAlign-top {
    top: -1px;
    bottom: auto;
    &.hAlign-left {
      transform: scale(-1, -1);
    }
    &.hAlign-right {
      transform: scale(1, -1);
    }
  }
  &.vAlign-middle {
    bottom: auto;
    top: 35%;
    &.hAlign-left {
      transform: scale(-1, -1);
    }
    &.hAlign-right {
      transform: scale(1, -1);
    }
  }

  &.vAlign-bottom {
    top: auto;
    bottom: 0;
    &.hAlign-left {
      transform: scale(-1, 1);
    }
    &.hAlign-right {
      transform: scale(1, 1);
    }
  }
}
