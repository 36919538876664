@mixin tertiary-small-button {
  &.tertiary-small {
    background: transparent;
    padding: 0px;
    height: auto;
    color: $tint-grey-37;

    svg path {
      fill: $tint-grey-37;
    }

    &:hover {
      background-color: transparent;
      color: darken($tint-grey-37, 10%);

      svg path {
        fill: darken($tint-grey-37, 10%);
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
