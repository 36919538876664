@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.container {
  position: relative;
  overflow: hidden;
}

.wrapper {
  position: relative;
  height: 50px;
  width: 100%;

  &.firstFade {
    -webkit-mask-image: linear-gradient(to right, transparent 0px, black 40px);
  }

  .lastFade {
    -webkit-mask-image: linear-gradient(to left, transparent 0px, black 40px);
  }
}

.tabsContainer {
  position: absolute;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  top: 1px;
  width: 100%;
}

.tabs {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

.tab {
  @include button-reset;
  padding: 0 24px;
  background: $tint-grey-3;
  border-radius: 14px 14px 0 0;
  border: $global-border-style;
  position: relative;
  font-weight: 500;
  flex-shrink: 0;
  height: 50px;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    background: white;
  }

  @include focus-custom();
}

.selectedTab {
  background: white;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background: white;
  }
}

.borderHider {
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -1px;
  left: 0;
  background: white;
}

.content {
  background: white;
  border-radius: 0 14px 14px 14px;
  border: $global-border-style;
  width: 100%;
  padding: 24px;

  p:last-child {
    margin-bottom: 0;
  }
}
