@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.wrapper {
  display: flex;
}

.tabs {
  margin-right: 24px;
  flex-grow: 1;
}

.sidebar {
  max-width: 340px;
  width: 340px;
  flex-shrink: 0;
}

.relatedProducts {
  margin-top: 40px;
}

.productHeader {
  position: relative;
  margin-bottom: 72px;

  .headerContent {
    z-index: 300;
    position: relative;
  }

  .background {
    width: 100vw;
    height: 100%;
    position: absolute;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    left: 0;
    top: 0;
    background-color: $brand-primary-lightest;

    .curve {
      position: absolute;
      bottom: 32px;
      width: 100vw;
      left: 0;
      z-index: 0;
      background: $brand-secondary-light;
      height: 100vh;
    }
  }
}

@media (max-width: 999px) {
  .wrapper {
    flex-direction: column;
  }
  .tabs {
    margin-right: 0;
  }

  .sidebar {
    max-width: 100%;
    width: 100%;
  }
}
