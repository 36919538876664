@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.recipeSidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.block {
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 8px;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.ingredientBlock {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  .ingredientBlockTitle {
    font-size: 14px;
    font-weight: 500;
  }
}

.kitchenTools {
}

.chef {
  display: flex;
  font-size: 14px;

  .chefDetails {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .chefAvatar {
      border-radius: 100px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-right: 16px;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}
