@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productCardLoading {
  height: 284px;
  max-height: 284px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 32px 16px 16px 16px;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-big;
  text-align: center;
}

.productCardLoadingSmall {
  height: 194px;
  max-height: 194px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 16px 16px 0px 16px;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-big;
  text-align: center;
  justify-content: center;

  @media (max-width: $breakpoint-small) {
    vertical-align: middle;
    max-height: 88px;
    padding: 0;
    justify-content: left;
    text-align: left;
    flex-direction: row;
  }
  .title {
    margin-top: 32px;
  }
}

.imageWrapper {
  width: 100%;
  height: 130px;
  background-color: $tint-grey-8;
  border-radius: $global-border-radius;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  > span {
    background-color: $tint-grey-8;
    border-radius: $global-border-radius;
    width: 100%;
    height: 12px;
    flex-grow: 0;

    &:last-child {
      width: 60%;
      margin-top: 8px;
    }
  }
}

.description {
  // display: flex;
  align-self: center;
  height: 12px;
  width: 32px;
  margin-top: auto;
  background-color: $tint-grey-8;
  border-radius: $global-border-radius;
}

@media (max-width: $breakpoint-xsmall-max) {
  .productCardLoading {
    padding: 4px;
    height: 72px;
    flex-direction: row;
    border-radius: $global-border-radius;
    box-shadow: $global-shadow-card-small;
    align-items: center;
  }

  .imageWrapper {
    width: 88px;
    min-width: 88px;
    height: 100%;
    margin-right: 8px;
  }

  .title {
    display: flex;
    margin-top: 0;
    > span {
      background-color: $tint-grey-8;
      border-radius: $global-border-radius;
      width: 90%;
      height: 10px;
      flex-grow: 0;

      &:last-child {
        width: 60%;
        margin-top: 8px;
      }
    }
  }

  .description {
    display: none;
  }
}
