@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pageNumberWrapper {
  display: flex;
}

.pageNumber {
  @include button-reset;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  position: relative;

  color: $global-subtext-color;
  font-size: 16px;
  color: $brand-primary;

  &:hover {
    color: $brand-primary-dark;
  }

  &.isSelected {
    color: $brand-primary;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 16px;
      height: 2px;
      background-color: $brand-primary;
    }
  }

  @media (max-width: $breakpoint-xsmall-max) {
    &.beforeSelected {
      display: none;
    }

    &.afterSelected {
      display: none;
    }
  }

  @media (max-width: 390px - 1) {
    display: none;

    &.isSelected {
      display: flex;
    }
  }
}

.arrows {
  @include button-reset;
  min-width: 40px;
  height: 40px;
  width: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: $brand-primary;
  border-radius: 100px;
  cursor: pointer;

  &.firstArrow {
    margin-right: 24px;
  }

  &.lastArrow {
    margin-left: 24px;
  }

  &:hover {
    background-color: $brand-primary-dark;
  }

  @media (max-width: 390px - 1) {
    &.firstArrow {
      margin-right: auto;
    }

    &.lastArrow {
      margin-left: auto;
    }
  }
}

.dots {
  color: $global-subtext-color;
  height: 32px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 390px - 1) {
    display: none;
  }
}

.arrowsDisabled {
  cursor: default;
  pointer-events: none;
  background-color: $brand-primary-light;
}
