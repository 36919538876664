@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.gallerySlider {
  margin: 0 auto;
  h2 {
    margin-bottom: 16px;
    min-height: 40px;
  }
  img {
    cursor: pointer;
  }
  @media (max-width: $breakpoint-medium-max) {
    width: 90%;
  }
  .image {
    width: 100%;
    height: 400px;
    border-radius: $global-border-radius;
    object-fit: cover;
  }
}
