@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.fullScreenSlider {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hide {
    display: none;
  }
}

.background {
  @include button-reset();
  background: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}

.close {
  @include button-reset();
  position: absolute;
  right: 16px;
  width: 64px;
  height: 64px;
  top: 16px;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.15);
  }

  svg path {
    fill: white;
  }
}

.sliderWrapper {
  display: block;
  width: 90vw;
  height: 90vh;

  button {
    pointer-events: initial;
  }

  .slider {
    position: relative;
    height: 100%;

    .imageWrapper {
      display: flex !important;
      height: 100%;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: $global-border-radius;
      position: relative;

      a.description {
        .linkIcon {
          margin-right: 8px;
          display: flex;
          svg path {
            fill: white;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, 0.7);
        }
      }

      .description {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        border-radius: 14px;
        background: rgba(0, 0, 0, 0.5);
        z-index: 100;
        bottom: 10px;
        color: white;
        padding: 8px;
        flex-grow: 1;
      }
      img {
        pointer-events: initial;
        max-width: 100%;
        max-height: 100%;
        border-radius: $global-border-radius;
      }
    }
  }
}

.withThumbnails {
  .slider {
    height: calc(100% - 72px - 24px);
  }

  .thumbnails {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    > div,
    > div > div {
      height: 100%;
      width: 100%;

      > div {
        height: 100%;
        width: 100%;
        > div > div {
          text-align: center;
        }
      }
    }

    @media (max-width: 479px) {
      display: none;
    }

    .thumbnail {
      display: inline-flex !important;
      height: 100%;
      justify-content: center;
      align-items: center;
      max-width: 150px;
      overflow: hidden;
      border-radius: $global-border-radius;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
