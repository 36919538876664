@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.socialMedia {
  text-align: center;

  margin: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  list-style: none;

  @media (max-width: $breakpoint-xsmall-max) {
    margin: auto;
  }
}

.label {
  padding-right: 16px;
}
