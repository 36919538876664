@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.featuredPosts {
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;

  .topButton {
    display: flex;
    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.bottomButton {
  display: none;
  margin-top: 16px;

  @media (max-width: 767px) {
    display: flex;
    width: 100%;
  }
}
