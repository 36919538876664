@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
@mixin transition-transform() {
  transition: transform 0.2s ease-in-out;
}

.instagramCard {
  display: flex;
  overflow: hidden;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-big;
  position: relative;

  &:hover {
    img,
    video {
      transform: scale(1.04);
    }
  }
  @include focus-custom();

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.imageWrapper {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include transition-transform;
  }
}
