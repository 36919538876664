@mixin secondary-button {
  &.secondary {
    background-color: $button-secondary-background;
    color: $button-primary-background;

    svg path {
      fill: $button-primary-background;
    }

    &:hover {
      background-color: $button-secondary-background-hover;

      svg path {
        fill: $button-primary-background-hover;
      }
    }

    &:active {
      background-color: $tint-grey-5;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
