@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.cardWrapper {
  height: 332px;
  max-height: 332px;
  background: white;
  padding: 32px 16px 16px 16px;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-big;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.02);
    .title {
      color: $brand-primary;
    }
  }

  &.smallCardWrapper {
    padding: 16px 16px 0px 16px;
    height: 194px;
    max-height: 194px;
  }
}

.buyButton {
  margin-top: auto;
}

.productCard {
  display: flex;
  flex-direction: column;
  text-align: center;

  @include focus-custom();
}

.productCardSmall {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  img {
    max-height: 96px;
  }

  @include focus-custom();

  @media (max-width: $breakpoint-small) {
    vertical-align: middle;
    max-height: 88px;
    padding: 0;
    justify-content: left;
    text-align: left;
    flex-direction: row;
    img {
      text-align: center;
      max-height: 72px;
      margin: 8px;
    }
  }
  .title {
    margin-top: 32px;
  }
}

.imageWrapper {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    max-width: 100%;
    max-height: 100%;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: $global-bodytext-color;
  margin-top: 16px;

  @include truncate-text(68, 3);
}

.description {
  margin-top: auto;
  font-size: 12px;

  color: $global-subtext-color;
}

@media (max-width: $breakpoint-xsmall-max) {
  .cardWrapper {
    box-shadow: $global-shadow-card-small;
    padding: 4px;
    height: 96px;
    max-height: 96px;
  }

  .buyButton {
    z-index: 1;
    position: relative;
    left: 0;
    margin-right: 100%;
    top: -48px;
    margin-left: 272px;
    width: 100px;
  }

  .productCard {
    flex-direction: row;
    border-radius: $global-border-radius;
    align-items: center;
    max-height: 100%;
    width: 100%;
  }

  .imageWrapper {
    width: 88px;
    min-width: 88px;
    height: 100%;
    margin-right: 8px;
    box-shadow: none;
    border-radius: 12px 0px 0px 12px;
  }

  .title {
    display: flex;
    margin-top: -40px;
    align-items: center;
    text-align: left;

    @include truncate-text(48, 2);
  }

  .description {
    display: none;
  }
}

.ctaCard {
  background-color: $brand-primary;
  justify-content: center;
  padding: 0;

  > a {
    padding: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    .title {
      color: white;
    }
  }

  .title {
    color: white;
    max-height: initial;

    line-height: initial;
    overflow: auto;
    display: initial;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
    margin-top: initial;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .description {
    display: none;
  }

  .linkIcon {
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg path {
      fill: white;
    }
  }
}
