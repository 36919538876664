@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.postOverview {
  display: flex;
  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
  }
}

.sidebar {
  margin-right: 40px;
  max-width: 380px;
  min-width: 280px;
  flex-shrink: 0;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 24px;
  }
}

.filterItem {
  max-width: 260px;

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    max-width: 100%;
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
}

.pagination {
  margin-top: 56px;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-top: 32px;
  }
}

.notFound {
  display: flex;
  justify-content: center;
}

.overview {
  flex-grow: 1;
}
