@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.modal {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 550ms, visibility 550ms;
  position: fixed; /* Stay in place */
  z-index: 101; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */

  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  &__center-content {
    position: fixed;
    left: 20%;
    width: 60%;
    z-index: 123;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 200ms, visibility 200ms;
    transition: opacity 200ms, visibility 200ms;
    @media (max-width: $breakpoint-medium-max) {
      left: 6%;

      width: 90%;
    }
  }

  &-open {
    visibility: visible;
    opacity: 1;
  }
}
