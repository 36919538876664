@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.container {
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  font-family: $global-font-family;

  &:hover {
    color: $brand-primary;

    .hamburgerMenu {
      span,
      span:before,
      span:after {
        background-color: $brand-primary;
      }

      &.opened {
        span {
          background-color: transparent;
        }
      }
    }
  }

  .text {
    font-size: 14px;
  }

  .hamburgerContainer {
    position: relative;
    width: 16px;
    height: 16px;
  }
}

.hamburgerMenu {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;

  span,
  span:before,
  span:after {
    content: "";
    position: absolute;
    border-radius: 1px;
    height: 2px;
    width: 16px;
    display: block;
    background: $tint-grey-78;
    transition: transform 100ms ease-in-out;
  }

  span {
    margin-top: 7px;
  }

  span:before {
    top: -5px;
  }

  span:after {
    bottom: -5px;
  }

  &.opened {
    span {
      background-color: transparent;
      &:before,
      &:after {
        top: 0;
      }

      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}
