@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productLineOverview {
  display: flex;
}

.sidebar {
  margin-right: 16px;
  max-width: 260px;
}

.overview {
  width: calc(100% - 276px);
  > div:last-child {
    padding-bottom: 20px;
  }
}

.productGroup {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.noResults {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

@media (max-width: 739px) {
  .productLineOverview {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .overview {
    width: 100%;
  }

  .productGroup {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 639px) {
  .productGroup {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.noResultsWithoutSidebar {
  width: 100%;
}
