@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.searchResults {
  display: flex;
}

.sidebar {
  margin-right: 16px;
  max-width: 260px;
  width: 260px;
  flex-shrink: 0;
}

.noResults {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.overview {
  flex-grow: 1;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.pagination {
  margin-top: 40px;
}

@media (max-width: 739px) {
  .searchResults {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .overview {
    width: 100%;
  }
}
