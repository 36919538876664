@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
@mixin transition() {
  transition: all 0.1s ease-in-out;
}

.smallCardFilled {
  font-family: $global-font-family-secondary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $global-border-radius;
  box-shadow: 0 6px 12px 0 rgba(black, 0.04);
  box-sizing: border-box;
  background-color: $brand-primary;
  height: 112px;
  box-shadow: $global-shadow-card-small;
  width: 100%;

  @include transition;

  &:hover {
    background: $brand-primary-dark;
    transform: scale(1.02);
  }

  .linkText > * {
    color: white;
    margin-left: 16px;
  }

  .image {
    display: none;
  }
}

.icon {
  display: flex;
  margin-right: 16px;
  svg path {
    fill: white;
  }
}
