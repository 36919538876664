@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.total {
  background: $brand-primary;
  color: white;
  border-radius: $global-border-radius;
  margin-left: 8px;
  font-size: 14px;
  padding: 0px 6px;
  min-width: 32px;
  text-align: center;
  font-weight: 500;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
