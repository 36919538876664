@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
$small-card-padding: 24/2;

.instagram {
  position: relative;
  overflow: hidden;
  margin-bottom: -40px;

  .collection {
    position: relative;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    > a {
      width: calc(50% - #{$small-card-padding}px);
      position: absolute;
    }

    > :nth-child(1) {
      left: 0;
      top: 0;
    }

    > :nth-child(2) {
      right: 0;
      top: 0;
    }
    > :nth-child(3) {
      left: 0;
      bottom: 0;
    }
    > :nth-child(4) {
      right: 0;
      bottom: 0;
    }
  }
}

.title {
  display: flex;
  align-items: center;
}

.icon {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: $brand-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  padding: 8px;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-right: 8px;
    height: 24px;
    width: 24px;
    padding: 6px;
  }
}
