@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.sponsorCard {
  padding: 4px;
  transition: transform 0.1s ease-in-out;
}

.imgWrapper {
  width: 100px;
  height: 100px;
  padding: 8px;
  border: $global-border-style;
  border-radius: $global-border-radius;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

a.sponsorCard:hover {
  transform: scale(1.05);
}
