@mixin primary-button {
  &.primary {
    background-color: $button-primary-background;
    color: white;

    svg path {
      fill: white;
    }

    &:hover {
      background-color: $button-primary-background-hover;
      color: white;

      svg path {
        fill: white;
      }
    }

    &:active {
      background-color: $brand-primary-darkest;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
