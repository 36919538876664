@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
@mixin flex-card-mobile {
  .cards {
    display: flex;
    flex-wrap: wrap;
    margin: -16px;
    grid-template-columns: none;
    grid-auto-rows: unset;
    grid-gap: unset;
    .card {
      width: 50%;
      padding: 16px;
      &,
      &.long,
      &.wide,
      &.fullWidth {
        height: 360px;
      }
    }
  }
}

@mixin flex-card-mobile-small {
  .cards {
    margin: -8px;

    .card {
      width: 100%;
      padding: 8px;
      &,
      &.long,
      &.wide,
      &.fullWidth {
        height: 172px;
      }
    }
  }
}

.productLineGrid {
  display: flex;
  flex-direction: column;
}

.filters {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;

  .filterItem {
    max-width: 260px;
    display: flex;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-auto-rows: 40px;
  grid-gap: 24px;
}

.card {
  display: flex;
  box-sizing: border-box;
  grid-row-start: span 6;
  width: 100%;

  &.long {
    grid-row-start: span 9;
  }
  &.longest {
    grid-row-start: span 12;
  }
  &.wide {
    grid-column-start: span 2;
  }
  &.fullWidth {
    grid-column-start: span 3;
  }
}

@media (max-width: 859px) {
  @include flex-card-mobile();
}

@media (max-width: $breakpoint-xsmall-max) {
  @include flex-card-mobile-small();

  .filters {
    flex-direction: column;
    margin-bottom: 16px;

    .filterItem {
      max-width: 100%;
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}

.select {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  > div {
    max-width: 350px;
  }
}
