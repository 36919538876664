@mixin hook-base-body() {
  text-rendering: geometricPrecision;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: $breakpoint-small-max) {
    font-size: 16px;
    line-height: 1.38;
  }
}

* {
  box-sizing: border-box;
}

ul:not([class]) {
  padding: 0;
  > li {
    position: relative;
    list-style: none;
    padding-left: 29px;
    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      top: 0.5em;
      left: 5px;
      border-radius: 50%;
      background-color: $brand-primary;
    }
    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }
}

ol:not([class]) {
  counter-reset: ol;
  padding: 0;
  > li {
    position: relative;
    list-style: none;
    padding-left: 29px;
    &::before {
      counter-increment: ol;
      content: counter(ol) ".";
      position: absolute;
      top: 0;
      left: 5px;
      color: $brand-primary;
    }
    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }
}

blockquote {
  background-color: rgba(105, 190, 40, 0.05);
  color: $button-primary-background;
  padding: 16px 24px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  &::before {
    color: $button-primary-background;
    content: "\2033";
    opacity: 0.2;
    position: absolute;
    left: 8px;
    top: 9px;
    font-size: 58px;
    font-style: italic;
    line-height: 0.9;
    z-index: -1;
    @media (max-width: $breakpoint-small-max) {
      left: 1px;
      top: 6px;
    }
  }
  p {
    margin: 0;
  }
  @media (max-width: $breakpoint-small-max) {
    font-size: 15px;
    padding: 16px;
  }
}

.uk-text {
  &-small {
    @media (min-width: $breakpoint-large) {
      font-size: 16px;
    }
  }
  &-big {
    @media (min-width: $breakpoint-large) {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
    }
  }
}

a {
  color: $button-primary-background;

  &:hover {
    text-decoration: none;
    color: $button-primary-background-hover;
  }

  @include focus-custom();
}

button {
  @include focus-custom();
}

input {
  font-family: $global-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %heading;
}

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %h4;
}

h5 {
  @extend %h5;
}

h6 {
  @extend %h6;
}

@mixin hook-base-blockquote() {
  @media (max-width: $breakpoint-small-max) {
    font-size: 1rem;
  }
}

.uk-container {
  &.uk-container-medium {
    max-width: $container-medium-max-width;
  }
}

// Style from wordpress that gets added to all <caption> elements.
.wp-caption-text {
  color: $tint-grey-51;
  font-size: 14px;
}

em {
  color: inherit;
}
