@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.textFieldInput {
  height: 45px;
  input {
    height: 100%;
    color: $tint-grey-78;
    font-size: 16px;
    font-family: $global-font-family;
    border-radius: $global-border-radius;
    background-color: white;
    background-image: none;
    box-shadow: inset 0 0 0 1px $tint-grey-8;
    &:hover {
      box-shadow: inset 0 0 0 1px $tint-grey-37;
    }
    &:focus {
      box-shadow: 0 0 0 3px rgba($brand-primary, 0.4);
      background-image: none;
    }
  }

  input.error {
    background-image: none;
    box-shadow: inset 0 0 0 1px $error;
    &:hover {
      box-shadow: inset 0 0 0 1px $tint-grey-37;
    }
    &:focus {
      background-image: none;
      box-shadow: 0 0 0 3px rgba($brand-primary, 0.4);
    }
  }
}
