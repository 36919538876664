@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.informationBlock {
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 16px;
  }

  &:not(:first-child) {
    margin-top: 32px;
  }
}

.details {
  display: flex;

  flex-wrap: wrap;
}

.toggleButton {
  margin-top: 16px;
}

.allergenDisclosure {
  margin-top: 16px;
}
