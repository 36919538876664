@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productGroupOverviewDownloads {
  h2 {
    margin-bottom: 24px;
  }
}

.downloadList {
  margin-bottom: 16px;
  background: white;
  border-radius: $global-border-radius;
  padding: 16px;
}

.zipDownloads {
  display: flex;
  flex-wrap: wrap;

  padding-top: 8px;

  &.border {
    padding-top: 16px;
    margin-top: 16px;
    border-top: $global-border-style;
  }

  > :not(:last-child) {
    margin-right: 24px;
  }

  > * {
    margin-bottom: 8px;
    & span {
      font-size: 14px;
      text-align: left;
    }
  }
}
