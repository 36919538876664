@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.relatedProducts {
  margin-top: 72px;
}

.hint {
  margin-top: 40px;
}

.preparation {
  line-height: 2;
  ol li {
    margin-top: 0 !important;
  }
}
