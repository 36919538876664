@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
@import "./style-overrides/button-primary.scss";
@import "./style-overrides/button-secondary.scss";
@import "./style-overrides/button-tertiary.scss";
@import "./style-overrides/button-tertiary-small.scss";

a.buttonBase,
button.buttonBase {
  font-family: $global-font-family;
  font-weight: 500;
  border-radius: $global-border-radius;
  padding: 0 24px;
  height: 45px;
  display: inline-flex;
  background: transparent;

  &:hover {
    .iconLeft,
    .iconRight {
      transform: translateX(2px);
    }
  }

  &:focus {
    box-shadow: none;
  }

  @include focus-custom();

  &:active {
    background: transparent;
  }

  @include primary-button();
  @include secondary-button();
  @include tertiary-button();
  @include tertiary-small-button();
}

.title {
  &.titleRight {
    margin-left: 8px;
  }
  &.titleLeft {
    margin-right: 8px;
  }
}

.icon {
  display: flex;
  &.iconLeft,
  &.iconRight {
    transition: all 0.15s ease-in-out;
  }
}
