@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.breadcrumbs {
  font-size: 14px;
  display: flex;
  justify-content: center;

  color: $tint-grey-51;
  a {
    color: $tint-grey-51;
    &:hover {
      color: $brand-primary;
      span {
        stroke: $brand-primary;
      }
    }
  }
  span {
    stroke: $tint-grey-51;
  }

  li {
    &:nth-child(n):last-child {
      span {
        color: lighten($tint-grey-51, 10%);
      }
    }

    &:not(:last-child) {
      &::after {
        color: lighten($tint-grey-51, 10%);
      }
    }
  }

  li {
    margin: 0;
    display: inline-flex;
    align-items: center;

    &:not(:last-child) {
      &::after {
        margin-left: 8px;
        margin-right: 8px;
        display: block;
        content: "\2014";
      }
    }
  }

  ul {
    margin: 0;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.ul-breadcrumbs {
  padding: 0;
}

.back-button {
  display: flex;
  align-items: center;
}

.back-button-text {
  margin-left: 8px;
}

@media (max-width: $breakpoint-xsmall-max) {
  .breadcrumb {
    justify-content: flex-start;
  }
}
