@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.cookieContainer {
  position: fixed;
  width: calc(100% - 32px);
  bottom: 16px;
  margin: 0 16px;
  left: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookieBox {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  background: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  border-radius: $global-border-radius;
  padding: 16px;
}

.imageWrapper {
  width: 88px;
  height: 88px;
  flex-shrink: 0;
  margin-right: 24px;
}

.buttons {
  display: flex;
  margin-top: 16px;
  margin-left: auto;

  > a,
  button {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

@media (max-width: $breakpoint-small-max) {
  .imageWrapper {
    display: none;
  }
}

@media (max-width: $breakpoint-xsmall-max) {
  .buttons {
    flex-direction: column;
  }
}
