@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.mapMarker {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -16px;
  top: -44px;
  font-family: $global-font-family;
}

.marker {
  width: 32px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transform-origin: center bottom;
  transition: transform 0.1s ease-in-out;

  img {
    max-width: 100%;
    height: 100%;
  }
}

.infoBox {
  position: absolute;
  background: white;
  box-shadow: $global-shadow-card-small;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  transform-origin: center bottom;
  transition: all 0.1s ease-in-out;
  transform: translateY(-32px);
  opacity: 0;
  padding: 4px 8px;
  font-family: $global-font-family;
  font-size: 14px;
  bottom: 0;
  max-width: 320px;

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: #fff;
    border-width: 12px;
    margin-left: -12px;
    transform: translateY(-12px);
    opacity: 0;
    transition: transform 0.1s ease-in-out;
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: $global-bodytext-color;
}

.body {
  display: none;
  color: $global-bodytext-color;
  margin-top: 8px;
  margin-bottom: -8px;
  flex-direction: column;
}

.hover {
  z-index: 200;
  .marker {
    transform: scale(1.15);
    transform-origin: center bottom;
    transition: transform 0.1s ease-in-out;
    cursor: pointer;
  }

  .infoBox {
    opacity: 1;
    transform: translateY(-56px);
  }
}

.active {
  .marker {
    transform: scale(1.15);
    transform-origin: center bottom;
    transition: transform 0.1s ease-in-out;
    cursor: pointer;
  }

  .infoBox {
    z-index: 300;
    opacity: 1;
    padding: 16px;
    transform: translateY(-64px);

    &:after {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .body {
    display: flex;
  }
}

.listItem {
  display: flex;
  align-items: center;
  min-width: 280px;
  padding: 4px 16px;
  margin-left: -16px;
  margin-right: -16px;
  min-height: 36px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    svg path {
      fill: $global-bodytext-color;
    }
  }
}

a.listItem {
  &:hover {
    background: $brand-primary-lightest;
    .icon {
      svg path {
        fill: $brand-primary;
      }
    }
  }
}
