@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.contactContainer {
  display: flex;
  flex-wrap: wrap;

  h2 {
    margin-bottom: 40px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
  }

  .contactInfo {
    flex: 1;
    width: 50%;

    h2 {
      margin-bottom: 32px;
    }

    .block {
      h3 {
        margin-bottom: 16px;
      }
    }

    @media (max-width: $breakpoint-xsmall-max) {
      width: 100%;
    }
  }

  .description {
    margin-bottom: 24px;
  }
}

.followBlock {
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  .followItems {
    display: flex;
  }
}

.followUs {
  margin-bottom: 16px;
}

.contactForm {
  // max-width: 560px;
  width: 50%;
  margin-left: 40px;

  iframe {
    width: 100% !important;
  }

  h3 {
    margin-bottom: 32px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }
}

.socialItem {
  display: flex;
  &:hover {
    opacity: 0.8;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
  .socialImage {
    width: 24px;
    height: 24px;
  }
}
