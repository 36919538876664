@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
@mixin getColumns($count, $gap) {
  -moz-column-count: $count;
  -webkit-column-count: $count;
  column-count: $count;

  -moz-column-gap: #{$gap}px;
  -webkit-column-gap: #{$gap}px;
  column-gap: #{$gap}px;
}

.downloadList {
  &.withCard {
    background: white;
    border-radius: $global-border-radius;
    padding: 16px;
  }

  ul {
    margin-top: 12px;
    margin-bottom: 0;
    list-style-type: none;
    padding-left: 0;
    @include getColumns(2, 16);

    &.oneColumn {
      @include getColumns(1, 0);
    }

    @media (max-width: $breakpoint-xsmall - 1) {
      @include getColumns(1, 0);
    }

    @media (max-width: $breakpoint-xsmall - 1) {
      li {
        margin-bottom: 4px;
      }
    }

    &:not([class]) > li {
      padding-left: 0;
      &:before {
        content: none;
      }
    }
  }
}

.downloadLink {
  break-inside: avoid-column;

  a,
  button {
    @include button-reset();
    font-size: 14px;
    word-break: break-all;
    min-height: 20px;
    display: flex;
    align-items: center;
    line-height: 16px;
    color: $brand-primary;
    text-align: left;
    &:hover {
      .icon svg path {
        fill: $brand-primary;
      }
    }

    @include focus-custom();
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg path {
    fill: $brand-primary;
  }
}

.title {
  font-weight: 600;
}
