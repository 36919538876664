@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend %heading;
}

.h1 {
  @extend %h1;
}

.h2 {
  @extend %h2;
}

.h3 {
  @extend %h3;
}

.h4 {
  @extend %h4;
}

.h5 {
  @extend %h5;
}

.h6 {
  @extend %h6;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.justify {
  text-align: justify;
}
