@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.lightbox__background {
  background: white;
  padding: 40px;
  z-index: 9999;
}
.size__lightbox {
  width: 100%;
  height: 500px;
  object-fit: contain;
}
.caption-lightbox {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  > span {
    color: #b3b3b3;
  }
}
