@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.faqFilterItem {
  list-style: none !important;
  &:not(:first-child) {
    margin-top: 12px;
  }
  > button {
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
    display: inline-flex;
    list-style: none;
    align-items: center;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    font-family: $global-font-family;
    color: $global-subtext-color;
    cursor: pointer;

    &.active,
    &:hover {
      color: $brand-primary;
      text-decoration: none;
      .icon svg path {
        fill: $brand-primary;
      }
    }

    @include focus-custom();
  }

  &.isActive {
    > button {
      color: $brand-primary;
    }
    .icon svg path {
      fill: $brand-primary;
    }
  }
}

.icon {
  display: flex;
  margin-right: 8px;
  path {
    fill: $global-subtext-color;
  }
}
