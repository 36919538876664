@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.recipeHeader {
  position: relative;
}

.mainContent {
  z-index: 300;
  position: relative;
}

.recipeImage {
  position: relative;
  display: flex;
  min-height: 400px;
  margin-top: 24px;

  @media (max-width: $breakpoint-small-medium-max) {
    margin-bottom: 180px;
  }
}

.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.content {
  padding: 40px;
  display: flex;
  z-index: 1;

  @media (max-width: $breakpoint-small-medium-max) {
    position: absolute;
    bottom: -180px;
    padding: 0 24px;
    width: 100%;

    .infoCard {
      width: 100%;
      max-width: 100%;
    }
  }
}

.imageSliderBlock {
  width: 100%;
  height: 100%;
  position: absolute;

  .slider {
    position: relative;
    height: 100%;

    .imageWrapper {
      @include button-reset();
      height: 100%;
      overflow: hidden;
      border-radius: $global-border-radius;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.infoCard {
  align-self: flex-start;
  background: white;
  border-radius: $global-border-radius;
  padding: 24px;
  box-shadow: $global-shadow-card-big;

  width: 310px;
  max-width: 350px;
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 4px;
  }

  .listIcon {
    display: flex;

    &.indent {
      margin-left: 24px;
    }

    .icon {
      margin-top: 5px;
      display: flex;
      margin-right: 8px;
    }
  }
}

.background {
  width: 100vw;
  height: 100%;
  position: absolute;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  left: 0;
  top: 0;
  background-color: $brand-primary-lightest;

  .curve {
    position: absolute;
    bottom: 280px;
    width: 100vw;
    left: 0;
    z-index: 0;
    background: $brand-secondary-light;
    height: 100vh;
  }
}

.tags {
  color: $brand-secondary;
  margin: -2px;
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;

  .tagWrapper {
    padding: 2px;
  }

  .tag {
    display: flex;
    font-weight: 500;
    font-size: 13px;
    padding: 6px 10px;
    background: darken($brand-secondary-light, 5%);
    border-radius: $global-border-radius;
  }
}

.shareBlock {
  margin-top: 8px;
  .shareLabel {
    font-size: 14px;
    font-weight: 500;
  }

  .share {
    display: flex;
    align-items: center;
    .print {
      @include button-reset();
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: $brand-secondary;
      width: 24px;
      height: 24px;
      margin-left: 8px;

      svg {
        pointer-events: none;
      }

      svg path {
        fill: white;
      }

      &:hover {
        background: $brand-secondary-dark;
      }
    }

    @media (max-width: $breakpoint-xsmall-max) {
      > div {
        margin: 0;
      }
    }
  }
}
