@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
$icon-chevron-down: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4Ljk0OCA3LjIzMzc1QzE5LjQ1MDEgNi43MzE2NSAyMC4yNjQxIDYuNzMxNjUgMjAuNzY2MiA3LjIzMzc1QzIxLjI2ODMgNy43MzU4NiAyMS4yNjgzIDguNTQ5OTMgMjAuNzY2MiA5LjA1MjAzTDEzLjA1MiAxNi43NjYzQzEyLjU0OTkgMTcuMjY4NCAxMS43MzU4IDE3LjI2ODQgMTEuMjMzNyAxNi43NjYzTDMuNTE5NCA5LjA1MjAzQzMuMDE3MyA4LjU0OTkzIDMuMDE3MyA3LjczNTg2IDMuNTE5NCA3LjIzMzc1QzQuMDIxNSA2LjczMTY1IDQuODM1NTcgNi43MzE2NSA1LjMzNzY3IDcuMjMzNzVMMTIuMTQyOCAxNC4wMzg5TDE4Ljk0OCA3LjIzMzc1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==";
$language: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyQzAgNS4zNzI1OCA1LjM3MjU4IDAgMTIgMEMxOC42Mjc0IDAgMjQgNS4zNzI1OCAyNCAxMkMyNCAxOC42Mjc0IDE4LjYyNzQgMjQgMTIgMjRDNS4zNzI1OCAyNCAwIDE4LjYyNzQgMCAxMlpNNS4yNzU2NiAxOS4wNjAyQzcuMDI1MDMgMjAuNzI2OCA5LjM5MzAzIDIxLjc1IDEyIDIxLjc1QzE3LjM4NDggMjEuNzUgMjEuNzUgMTcuMzg0OCAyMS43NSAxMkMyMS43NSA5Ljk3Mjc4IDIxLjEzMTMgOC4wOTAwOCAyMC4wNzI1IDYuNTMwMzhDMTguMjk2MiA3LjIgMTUuMjQ3IDcuMiAxNSA3LjJDMTMuOTUgNy4yIDEyLjc1IDYuNDUgMTIgNS4yNUMxMS42NzY0IDQuODE4NDcgMTEuMTk3NSAzLjQ1NTgzIDEwLjg0MjUgMi40NDU2M0wxMC44NDI1IDIuNDQ1NjFMMTAuODQyNSAyLjQ0NTU3QzEwLjgyOCAyLjQwNDE1IDEwLjgxMzYgMi4zNjMzMyAxMC43OTk1IDIuMzIzMThDOC45Mzc0MiAyLjU1MTgyIDcuMjM3NzQgMy4zMDU0MyA1Ljg1MzAxIDQuNDMxNDRDNy4wOTYxNSA1Ljg0NDQ2IDguOTg1NzYgOC4xMzc3IDguMSA5LjE1QzcuNzAxNjEgOS42ODExOCA2LjkyMTE0IDEwLjA2NTQgNi4xNzQ5IDEwLjQzMjhDNS4yMzUzMSAxMC44OTUzIDQuMzUgMTEuMzMxMiA0LjM1IDEyQzQuMzUgMTIuNjM0MiA0Ljk0NTkyIDEzLjM0MyA1LjU2MDM0IDE0LjA3MzdDNS44MTc4MSAxNC4zNzk5IDYuMDc4NTMgMTQuNjg5OSA2LjMgMTVDNy4xMTM1NCAxNi4wODQ3IDcuNDM2ODIgMTcuNjU5NyA1LjI3NTY2IDE5LjA2MDJaTTE1LjMgMTAuMDQ5OUMxMy41IDEwLjA0OTkgMTEuODUgMTEuMjQ5OSAxMiAxMi44OTk5QzEyLjE3NyAxNC4zNzQ5IDEyLjA5ODcgMTUuNDc4NiAxMi4wMzkgMTYuMzIwNUMxMS45NDY5IDE3LjYxOTEgMTEuODk5IDE4LjI5NDkgMTIuOSAxOC43NDk5QzE0Ljg1IDE5LjQ5OTkgMTguNzUgMTUuODk5OSAxOC42IDEyLjg5OTlDMTguNiAxMS4yNDk5IDE3LjI1IDEwLjA0OTkgMTUuMyAxMC4wNDk5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==";
$language-white: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyQzAgNS4zNzI1OCA1LjM3MjU4IDAgMTIgMEMxOC42Mjc0IDAgMjQgNS4zNzI1OCAyNCAxMkMyNCAxOC42Mjc0IDE4LjYyNzQgMjQgMTIgMjRDNS4zNzI1OCAyNCAwIDE4LjYyNzQgMCAxMlpNNS4yNzU2NiAxOS4wNjAyQzcuMDI1MDMgMjAuNzI2OCA5LjM5MzAzIDIxLjc1IDEyIDIxLjc1QzE3LjM4NDggMjEuNzUgMjEuNzUgMTcuMzg0OCAyMS43NSAxMkMyMS43NSA5Ljk3Mjc4IDIxLjEzMTMgOC4wOTAwOCAyMC4wNzI1IDYuNTMwMzhDMTguMjk2MiA3LjIgMTUuMjQ3IDcuMiAxNSA3LjJDMTMuOTUgNy4yIDEyLjc1IDYuNDUgMTIgNS4yNUMxMS42NzY0IDQuODE4NDcgMTEuMTk3NSAzLjQ1NTgzIDEwLjg0MjUgMi40NDU2M0wxMC44NDI1IDIuNDQ1NjFMMTAuODQyNSAyLjQ0NTU3QzEwLjgyOCAyLjQwNDE1IDEwLjgxMzYgMi4zNjMzMyAxMC43OTk1IDIuMzIzMThDOC45Mzc0MiAyLjU1MTgyIDcuMjM3NzQgMy4zMDU0MyA1Ljg1MzAxIDQuNDMxNDRDNy4wOTYxNSA1Ljg0NDQ2IDguOTg1NzYgOC4xMzc3IDguMSA5LjE1QzcuNzAxNjEgOS42ODExOCA2LjkyMTE0IDEwLjA2NTQgNi4xNzQ5IDEwLjQzMjhDNS4yMzUzMSAxMC44OTUzIDQuMzUgMTEuMzMxMiA0LjM1IDEyQzQuMzUgMTIuNjM0MiA0Ljk0NTkyIDEzLjM0MyA1LjU2MDM0IDE0LjA3MzdDNS44MTc4MSAxNC4zNzk5IDYuMDc4NTMgMTQuNjg5OSA2LjMgMTVDNy4xMTM1NCAxNi4wODQ3IDcuNDM2ODIgMTcuNjU5NyA1LjI3NTY2IDE5LjA2MDJaTTE1LjMgMTAuMDQ5OUMxMy41IDEwLjA0OTkgMTEuODUgMTEuMjQ5OSAxMiAxMi44OTk5QzEyLjE3NyAxNC4zNzQ5IDEyLjA5ODcgMTUuNDc4NiAxMi4wMzkgMTYuMzIwNUMxMS45NDY5IDE3LjYxOTEgMTEuODk5IDE4LjI5NDkgMTIuOSAxOC43NDk5QzE0Ljg1IDE5LjQ5OTkgMTguNzUgMTUuODk5OSAxOC42IDEyLjg5OTlDMTguNiAxMS4yNDk5IDE3LjI1IDEwLjA0OTkgMTUuMyAxMC4wNDk5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==";

.customSelect {
  width: 100%;
  height: 45px;
  font-size: 16px;
  border: 0px;
  text-indent: 4px;
  background-color: white;
  box-shadow: inset 0 0 0 1px $tint-grey-8;
  border-radius: $global-border-radius;
  color: $global-bodytext-color;
  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 3px rgba($brand-primary, 0.4);
  }
  select {
    font-size: 16px;
    font-family: $global-font-family;
    border-radius: $global-border-radius;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &:after {
    content: "";
    background: url($icon-chevron-down) no-repeat;
    background-position: center;
    background-size: 16px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    width: 40px;
    height: 100%;
    text-align: center;
    font-size: 16px;
    z-index: -1;
  }
}
.fixCrossBrowserStyling {
  border: none;
  border-radius: 0;
  padding: 8px;
  padding-left: 12px;
  font-size: 18px;
  line-height: 18px;
  background-size: 34px 34px;
  padding-right: 34px;
  height: auto;
  background: none;
  background-color: transparent;
  &:disabled {
    background: none;
    background-color: transparent;
  }
}
.placeholder {
  color: $global-bodytext-color;
}

.customSelect.languageSwitcher {
  select {
    padding-left: 32px;
  }

  &:before {
    content: "";
    background: url($language) no-repeat;
    background-position: center;
    background-size: 16px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    width: 40px;
    height: 100%;
    text-align: center;
    font-size: 16px;
    z-index: -1;
  }
}

.customSelect.languageSwitcherNavBar {
  background: transparent;
  border: none;
  box-shadow: none;
  height: auto;

  :hover {
    outline: none;
    box-shadow: 0 0 0 2px rgba(white, 0.4);
  }

  select {
    font-size: 12px;
    color: white;
    padding-left: 24px;
    padding-right: 0;
    cursor: pointer;
  }

  &:before {
    content: "";
    background: url($language-white) no-repeat;
    background-position: center;
    background-size: 16px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    width: 20px;
    height: 100%;
    text-align: center;
    font-size: 16px;
    z-index: -1;
  }

  &:after {
    content: none;
  }
}
