@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.header {
  display: flex;
  flex-direction: column;
}

.information {
  margin-top: 72px;
  width: 100%;

  @media (max-width: $breakpoint-small-medium-max) {
    margin-top: 40px;
  }
}

.main {
  width: 100%;
}
