@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.footer {
  font-size: 16px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      &::before {
        display: block;
      }
      a {
        color: $tint-grey-49;
        &:hover {
          color: $brand-primary;
          text-decoration: none;
        }
      }
    }
  }
}

.bottom {
  padding: 16px 0;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
  }
}

.left {
  display: flex;
  align-items: center;
  width: 33.33%;

  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 32px;
    font-size: 16px;

    li:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li:not(:last-child) {
      padding-right: 24px;
    }
    @media (max-width: $breakpoint-xsmall-max) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  width: 33.33%;

  @media (max-width: $breakpoint-xsmall-max) {
    display: none;
  }
}

.social {
  width: 33.33%;
  text-align: center;
  display: flex;
  justify-content: flex-end;

  @media (max-width: $breakpoint-xsmall-max) {
    width: 100%;
    justify-content: center;
    flex-direction: column;

    > div {
      flex-direction: column;
      > span {
        padding: 0;
        margin-bottom: 16px;
      }
    }
  }
}

.logo {
  width: 86px;
  @media (max-width: $breakpoint-medium) {
    width: 86px;
  }
}
