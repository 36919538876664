@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.toggleWrapper {
  display: flex;
  justify-content: center;
}

.toggle {
  max-width: 740px;
  width: 100%;
}
