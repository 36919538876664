@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.hamburgerMenu {
  display: flex;
  position: absolute;
  height: 100vh;
  background: white;
  padding: 50px 24px;
  width: 300px;
  flex-direction: column;
  overflow: hidden;
  transform: translateX(-100%);
  transition: all 0.15s ease-in-out;

  ul:not([class]) > li {
    padding-left: 0px;
  }
  ul:not([class]) > li::before {
    content: none;
  }

  @media screen and (min-width: $breakpoint-xsmall-max) {
    display: none;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
  }

  li {
    display: flex;
    align-items: center;
    height: 20px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &.illustrationList {
      height: auto;

      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }

  .menuItemsWrapper {
    a,
    button {
      @include button-reset();
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
      color: $global-bodytext-color;
      display: flex;
      line-height: 1;
      align-items: center;
      white-space: nowrap;
      padding: 8px 0;

      &:hover {
        color: $brand-primary;

        .image {
          svg path {
            fill: $brand-primary;
          }
        }

        .moreIcon {
          svg path {
            fill: $brand-primary;
          }
        }
      }
    }
  }
}

.menuItemsWrapper {
  display: flex;
  width: 100%;
}

.menuItems {
  display: flex;
  width: 100%;
  transition: all ease 0.2s;

  > * {
    flex-shrink: 0;
    width: 100%;
    margin-right: 40px;

    &:not(:first-child) {
      a {
        font-weight: 400;
      }
    }
  }
}

.moreIcon {
  margin-left: 20px;
}

.image {
  display: flex;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;

  &.withTitle {
    margin-right: 8px;
  }
  &.illustration {
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  svg path {
    fill: $global-bodytext-color;
  }
}

.backButton {
  color: $global-bodytext-color;
  font-weight: 500;

  &:focus {
    outline: none;
  }
  &:hover {
    svg path {
      fill: $brand-primary;
    }
  }
}

.backButtonIcon {
  width: 12px;
  height: 12px;
  display: flex;
  margin-right: 8px;

  svg path {
    fill: $global-bodytext-color;
  }
}

.show {
  transform: translateX(0);
  transition: all 0.15s ease-in-out;
}

@media (max-width: $breakpoint-small-medium-max) {
  .hamburgerMenu {
    width: 100%;

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: $global-border-color;
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }
}

.languageSwitcher {
  > div > div > select {
    font-size: 14px !important;
  }
}
