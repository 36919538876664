@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productHeader {
  display: flex;
  position: relative;

  @media (max-width: $breakpoint-xsmall-max) {
    display: block;
  }

  .slider {
    display: none;
    .sliderContainer {
      height: 200px;
      display: flex !important;
      justify-content: center;
      align-items: center;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    @media (max-width: $breakpoint-xsmall-max) {
      display: block;
      margin-bottom: 16px;
      padding: 16px;
      background: white;
      border-radius: $global-border-radius;
      box-shadow: $global-shadow-card-big;
    }
  }
}

.headerImage {
  max-width: 300px;
  display: flex;
  margin-right: 40px;
  flex-direction: column;
  @media (max-width: $breakpoint-xsmall-max) {
    display: none;
  }
  .mainImgWrapper {
    background: white;
    border-radius: 14px;
    box-shadow: $global-shadow-card-big;
    width: 300px;
    height: 300px;
    cursor: pointer;
    flex-grow: 1;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .productImage {
    max-height: 80%;
    max-width: 80%;
  }
}

.imageSliderWrapper {
  display: flex;
  position: relative;

  button {
    @include button-reset();
  }

  .smallImg {
    display: flex;
    background: white;
    border-radius: 14px;
    min-height: 100%;
    box-shadow: $global-shadow-card-big;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    max-width: 33%;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    .smallProductImage {
      max-height: 80%;
      max-width: 80%;
    }
  }
}

.sharing {
  margin-top: 16px;
  span {
    color: $global-subtext-color;
  }
}
.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.details {
  color: $global-subtext-color;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 0 1 65%;
  @media (max-width: $breakpoint-small-max) {
    flex: 0 1 50%;
  }
  > span:first-of-type {
    // color: $gray-lighter;
    margin: 15px 0px 20px 0px;
  }
}

.body {
  p:last-child {
    margin-bottom: 0;
  }
}
