@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
@mixin transition() {
  transition: all 0.1s ease-in-out;
}

.smallCard {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: $global-border-radius;
  width: 100%;
  align-self: baseline;
  text-decoration: none !important;
  height: 112px;
  box-sizing: border-box;
  box-shadow: $global-shadow-card-small;
  @include transition;

  &:hover {
    transform: scale(1.02);
    .linkText {
      color: $brand-primary-dark;
    }
    .icon {
      svg path {
        fill: $brand-primary-dark;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
}

.image {
  width: 96px;
  height: 100%;
  border-radius: 12px 0 0 12px;
  object-fit: cover;
  @media (max-width: $breakpoint-small-max) {
    width: 88px;
  }
}

.linkText {
  color: $tint-grey-78;
  flex-grow: 1;
  @include truncate-text(40, 2);
  @include transition;

  @media (max-width: $breakpoint-medium-max) {
    font-size: 14px;
  }
  font-size: 16px;
}

.category {
  @media (max-width: $breakpoint-small-max) {
    font-size: 12px;
  }
}

.icon {
  display: flex;
  margin-right: 16px;
  margin-left: auto;

  svg path {
    @include transition;
    fill: $brand-primary;
  }
}
