@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.notificationToastContainer {
  position: fixed;
  width: 100%;
  bottom: 40px;
  left: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slideIn 0.3s forwards ease-in-out, slideOut 0.3s forwards ease-in-out;

  @media (max-width: $breakpoint-xsmall-max) {
    top: 40px;
  }
}

.notificationToast {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 500px;
  background: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
  overflow: hidden;
  margin: 16px;
}

.error {
  color: white;
  background: #d82e35;

  .bar,
  .barLoader {
    background: lighten(#d82e35, 30%);
  }
}

.bar,
.barLoader {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  position: absolute;
  background: $brand-primary-light;
  animation: bar 0.2s forwards ease-in-out;
}

.barLoader {
  animation: barInfinite 2s infinite linear;
}

@keyframes slideIn {
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(40px);
    opacity: 0;
  }
}

@keyframes bar {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes barInfinite {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
