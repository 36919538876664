@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
@mixin hover() {
  display: flex;
  cursor: pointer;
  align-items: center;

  &:not(:last-child) {
    margin-right: 8px;
  }

  svg circle {
    fill: $button-primary-background;
  }

  &:hover {
    > svg {
      opacity: 0.75;
    }
    span svg path {
      fill: $brand-primary-dark;
    }
  }
}

.buttons {
  display: inline-flex;
  overflow: hidden;
  flex-wrap: wrap;
  > * {
    @include hover();
  }

  button > svg {
    pointer-events: none;
  }

  .linkedIn {
    button {
      @include button-reset();
      @include hover();
    }
  }
}
