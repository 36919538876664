@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.postSearchResults {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
}

.post {
  color: $global-bodytext-color;
  font-weight: 500;
  font-size: 14px;
}

.cta {
  margin-top: 16px;
}
