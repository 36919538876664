@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.salePointModule {
  h2 {
    margin-bottom: 32px;
  }
}

.row {
  margin: -16px;
  display: flex;
  flex-wrap: wrap;
}

.map {
  height: 500px;
}

.column {
  padding: 16px;
  width: 30%;
  flex-grow: 1;

  &:first-child {
    width: 70%;
  }

  @media (max-width: $breakpoint-small-max) {
    width: 100%;
  }
}

.content {
  font-size: 14px;
}

.sponsors {
  margin-top: 16px;

  .sponsorRow {
    margin: -4px;
    display: flex;
    flex-wrap: wrap;
  }
}
