@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.teamCard {
  display: flex;
  overflow: hidden;
  border-radius: $global-border-radius;
  flex-direction: column;
  box-shadow: $global-shadow-card-big;
  background-color: #fff;

  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: row;
  }
}

.imageWrapper {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 250px;
  flex-shrink: 0;

  img.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    border-radius: 100px;
    width: 64px;
    height: 64px;
    margin: 16px;
    margin-right: 0;
  }

  @media (max-width: (359px - 1)) {
    width: 40px;
    height: 40px;
  }
}

.body {
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: $breakpoint-xsmall-max) {
    padding: 16px;
    padding-right: 40px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.title > * {
  color: $brand-secondary;
}

.role {
  color: $global-subtext-color;
  font-size: 14px;
}

.description {
  color: $global-subtext-color;
  font-size: 16px;
  margin-top: 16px;
  @media (max-width: $breakpoint-xsmall-max) {
    font-size: 14px;
  }
}

.linkedIn {
  position: absolute;
  width: 26px;
  height: 26px;
  left: 24px;
  top: -14px;
  overflow: hidden;

  @media (max-width: $breakpoint-xsmall-max) {
    right: 16px;
    bottom: auto;
    left: auto;
    top: 16px;
    width: 26px;
    height: 26px;
  }
}

a.teamCard {
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: scale(1.015);

    .button {
      color: $brand-primary-dark;
      > :last-child {
        transform: translateX(4px);
        svg path {
          fill: $brand-primary-dark;
        }
      }
    }
  }
}

.button {
  display: flex;
  font-weight: 500;
  align-items: center;
  margin-top: 16px;
  color: $brand-primary;

  > :last-child {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;

    svg path {
      fill: $brand-primary;
    }
  }
}
