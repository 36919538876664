@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.infoHeader {
  position: relative;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  &.alignLeft {
    justify-content: space-between;
  }

  &.isBlog {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 600px;
    margin: 0 auto;
    .image {
      width: 100%;
      margin-top: 24px;
      height: 300px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 600px;

  &.alignLeft {
    align-items: flex-start;
    text-align: left;
    .body {
      margin-right: 64px;
    }
    .breadcrumbs {
      justify-content: flex-start;
    }
  }

  &.isBlog {
    text-align: left;
    .breadcrumbs {
      justify-content: flex-start;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  margin-right: 0;

  .html {
    line-height: 24px;
    font-size: 16px;
  }

  h1 {
    margin-bottom: 24px;
  }

  .button {
    margin-top: 16px;
    z-index: 99;
  }
}

.breadcrumbs {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.image {
  overflow: hidden;
  border-radius: $global-border-radius;
  height: 300px;
  width: 50%;
  z-index: 99;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 767px) {
  .wrapper,
  .wrapper.alignLeft {
    align-items: center;
    text-align: center;
    .breadcrumbs {
      justify-content: center;
    }
    .body {
      margin-right: 0;
    }
  }

  .container {
    flex-direction: column;

    .image {
      margin-top: 24px;
      width: 100%;
      height: 250px;
    }
  }
}

@media (max-width: 479px) {
  .wrapper,
  .wrapper.alignLeft {
    align-items: flex-start;
    text-align: left;
    .breadcrumbs {
      justify-content: flex-start;
    }
  }
  .image {
    height: 200px;
  }
}
