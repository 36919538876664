@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productLineOverviewDownloads {
  h2 {
    margin-bottom: 24px;
  }
}

.groupCard {
  @include button-reset();
  display: flex;
  border-radius: $global-border-radius;
  min-height: 60px;
  box-shadow: $global-shadow-card-small;
  color: $global-bodytext-color;
  font-size: 14px;
  align-items: center;
  width: 100%;
  background: white;
  font-weight: 500;
  padding: 16px;
  text-align: left;

  &:hover {
    transform: scale(1.01);
  }

  @include focus-custom();

  .title {
    @include truncate-text(56, 3);
  }
}

.downloadList {
  margin-bottom: 16px;
  background: white;
  border-radius: $global-border-radius;
  padding: 16px;
}

.zipDownloads {
  display: flex;
  flex-wrap: wrap;

  padding-top: 8px;

  &.border {
    padding-top: 16px;
    margin-top: 16px;
    border-top: $global-border-style;
  }

  > :not(:last-child) {
    margin-right: 24px;
  }

  > * {
    margin-bottom: 8px;
    & span {
      font-size: 14px;
      text-align: left;
    }
  }
}
