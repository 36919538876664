@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.mainHeader {
  .topTitle {
    font-family: $global-font-family-secondary;
    color: $brand-secondary;
    font-size: 38px;
    font-weight: 600;
    text-transform: uppercase;
    @media (max-width: $breakpoint-small-max) {
      font-size: 30px;
    }
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  h1 {
    font-size: 55px;
    @media (max-width: $breakpoint-xsmall-max) {
      font-size: 40px;
    }
  }

  position: relative;
}

.imageWrapper {
  position: relative;
  height: 480px;
  @media (max-width: $breakpoint-small-max) {
    height: 334px;
  }

  .imageContent {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .welcomeText {
    position: relative;
    width: 50%;
    margin-top: -104px;
    @media (max-width: $breakpoint-small-max) {
      width: 60%;
      font-size: 18px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      width: 90%;
      margin-top: -80px;
    }
  }

  .gradient {
    height: 100%;
    position: absolute;
    width: 100%;
    background-size: 50% 100%, cover !important;
    background-position: left top, 100% 100% !important;
    background-repeat: no-repeat;
  }

  .backgroundImage {
    height: 100%;
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
}

.decor {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  fill: currentColor;
  transform: scale(-1, 1);
  z-index: 0;
}

.cardsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 48px;
  @media (max-width: $breakpoint-small-max) {
    margin-top: 32px;
  }
}

.cardContainer {
  display: flex;
  @media (max-width: $breakpoint-small-max) {
    flex-direction: column;
  }
}

.bigCardContainer {
  display: flex;
  width: 100%;
  z-index: 99;

  > a {
    &:first-child {
      margin-right: 32px;
      margin-bottom: 8px;
    }

    @media (max-width: 480px) {
      margin: 0px;
      height: auto;
      flex: 1 1 100%;

      &:first-child,
      &:last-child {
        margin-right: 0px;
      }

      &:last-child {
        > div:last-child {
          margin-bottom: 0px;
        }
      }

      & > div {
        margin-right: 0px;
        &:first-child {
          margin-right: 0px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.smallCardContainer {
  display: flex;
  flex-direction: column;

  > a {
    padding: 4px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    @media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium - 1) {
      &,
      &:not(:last-child) {
        margin-bottom: 0px;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
    @media (max-width: $breakpoint-xsmall-max) {
      height: 72px;
    }
  }

  @media (max-width: $breakpoint-small-max) {
    flex-direction: row;
    margin-top: 32px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    margin-top: 24px;
    flex-direction: column;
    flex-basis: 100%;
  }

  @media (max-width: 480px) {
    margin-top: 0px;
  }
}
