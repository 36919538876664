@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productLineSlider {
  display: flex;
}

.sliderMobile {
  display: none;
}

.groupCard {
  height: 332px;
  max-height: 332px;
  width: 210px;
  min-width: 210px;
  max-width: 210px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 24px;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-big;
  margin-right: 16px;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.02);
    .link .linkIcon {
      transform: translateX(2px);
      svg path {
        fill: $brand-primary-dark;
      }
    }
  }

  @include focus-custom();

  .title > * {
    color: $brand-secondary;
    overflow-wrap: break-word;
    hyphens: auto;
    font-size: 20px;
  }

  .link {
    display: flex;
    align-items: center;
    margin-top: auto;
    font-weight: 500;

    .linkText {
      margin-right: 8px;
    }

    .linkIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -1px;
      transition: all 0.1s ease-in-out;
      svg path {
        fill: $brand-primary;
      }
    }
  }
}

.slide {
  width: 100%;
  height: 100%;

  &.fakeSlide {
    pointer-events: none;
  }
}

.sliderContainer {
  width: calc(100% - 226px);
  display: flex;

  &.fullContainer {
    width: 100%;
  }
}

.sliderWrapper {
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.buttonBottom {
  display: none;
}

.desktopNoSlider {
  display: flex;
}

@media (max-width: 639px) {
  .sliderDesktop {
    display: none;
  }
  .sliderMobile {
    display: flex;
    flex-direction: column;
  }
  .productLineSlider {
    flex-direction: column;
  }

  .groupCard {
    height: auto;
    min-height: auto;
    max-height: initial;
    background: transparent;
    max-width: auto;
    width: auto;
    box-shadow: none;
    padding: 0;
    margin-bottom: 8px;
    margin-right: 0;
    .title {
      color: $global-bodytext-color;
    }
    .link {
      display: none;
    }
  }
  .slide {
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .slider {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .sliderWrapper {
    width: 100%;
  }

  .sliderContainer {
    width: 100%;
  }

  .buttonBottom {
    margin-top: 16px;
    display: flex;
  }
}
