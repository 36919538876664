@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.linkBlocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.description {
  margin-top: 8px;

  @media (max-width: $breakpoint-xsmall-max) {
    font-size: 14px;
  }
}

.links {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > :not(:last-child) {
    margin-bottom: 4px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    margin-top: 8px;
  }
}

.linkBlock {
  display: flex;
  flex-direction: column;

  a {
    justify-content: flex-start;
    > * {
      text-align: left;
    }
  }
}
