@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productLineGridCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.imageWrapper {
  max-height: calc(100% - 80px);
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-big;
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.body {
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-small;
  width: calc(100% - 32px);
  margin-left: 16px;
  background: white;
  height: 160px;
  padding: 24px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  margin-top: -80px;
  transition: transform 0.2s ease-in-out;

  &.leftAlign {
    width: 328px;
  }

  &:hover {
    transform: scale(1.02);
    transition: transform 0.2s ease-in-out;
    button,
    a {
      > *:last-child {
        transform: translateX(2px);
      }
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: $global-small-title-color;
  @include truncate-text(24, 1);
}

.description {
  color: $global-subtext-color;
  @include truncate-text(48, 2);
}

.link {
  margin-top: auto;
}

.icon {
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;

  svg path {
    fill: $brand-primary;
  }
}

@media (max-width: $breakpoint-xsmall-max) {
  .body {
    flex-direction: row;
    height: auto;
    padding: 16px;
    margin-top: -28px;
  }
  .title {
    margin-bottom: 0;
    color: $global-bodytext-color;
  }
  .link {
    margin-top: 0;
  }
  .imageWrapper {
    max-height: calc(100% - 26px);
  }
}
