@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.navBarSearch {
  position: absolute;
  width: 100vw;
  z-index: 10;
  height: 100vh;
  overflow: hidden;
  display: none;

  &.opened {
    display: initial;
  }

  .content {
    position: relative;
    margin-top: 34px;
    z-index: 2;
    background-color: white;
    @media screen and (max-width: 639px) {
      margin-top: 0px;
    }
  }
}

.highlighted {
  background-color: transparent;
  font-size: 15px;
  font-weight: 700;
  color: $button-primary-background-hover;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  transition: 250ms all ease-in-out;
  z-index: 1;
  @media (max-width: $breakpoint-xsmall-max) {
    background-color: white;
  }
}

.resultsWrapper {
  max-height: calc(100vh - 64px - 34px);
  overflow-x: hidden;
  width: calc(100% + 24px);

  .results {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    width: calc(100% - 24px);

    > :not(:last-child) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: $global-border-style;
    }
  }
}

.icon {
  display: flex;
  svg path {
    fill: $tint-grey-78;
  }
}

.searchArea {
  font-size: 16px;
  background: #fff;
  box-shadow: inset 0 -1px 0 0 $global-border-color;
  z-index: 2;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 64px;
  padding: 0;
  align-items: center;
  justify-content: space-between;

  div[role="button"] {
    &:hover {
      svg path {
        fill: $brand-primary;
      }
    }
  }

  > ul li {
    list-style-type: none;
  }
}

.item {
  list-style: none;
  margin: 8px 6px 8px 0px;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin: 15px 0px;
  }
}

.input {
  border: none;
  display: flex;
  font-size: 16px;
  flex-grow: 1;
  margin: 0px 8px;
  line-height: 3;
  padding-top: 2px;

  &:focus {
    outline: none;
  }
}

.close {
  @include button-reset;

  display: flex;
}

.totalResults {
  color: $global-subtext-color;
  font-size: 14px;
  margin-right: 8px;
}

.noResults {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

@media (max-width: $breakpoint-xsmall-max) {
  .recipesAndPosts {
    > div > div {
      &:not(:last-child) {
        .recipesAndPostsResults {
          padding-bottom: 24px;
          border-bottom: $global-border-style;
        }
      }
    }
  }
}
