@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
$vertical-spacing: 12px;
$horizontal-spacing: 24px;
$vertical-spacing-mobile: 8px;
$horizontal-spacing-mobile: 16px;
$card-padding: $vertical-spacing $horizontal-spacing;
$title-margin: -#{$vertical-spacing} -#{$horizontal-spacing};
$card-padding-mobile: $vertical-spacing-mobile $horizontal-spacing-mobile;
$title-margin-mobile: -#{$vertical-spacing-mobile} -#{$horizontal-spacing-mobile};

.expansionPanel {
  cursor: pointer;
  position: relative;
  padding: $card-padding;
  background: white;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-card-small;
  transition: all 0.1s ease-in-out;

  p {
    margin: 0;
  }

  &:hover {
    background-color: lighten($brand-primary, 70%);
  }

  &.animate {
    &:hover {
      transform: scale(1.01);
    }
  }

  &.isFocussed {
    box-shadow: 0 0 0 3px rgba($brand-primary, 0.4);
  }

  @media (max-width: $breakpoint-xsmall-max) {
    padding: $card-padding-mobile;
  }
}

.content {
  position: relative;
  font-size: 16px;
  cursor: initial;
  overflow: hidden;
  transition: height 0.25s ease-out;
}

.title {
  margin: $title-margin;
  padding: $card-padding;
  font-size: 18px;
  font-weight: 500;
  font-family: $global-font-family-secondary;
  color: $brand-primary;
  position: relative;
  z-index: 1;
  display: flex;

  .icon {
    margin-left: auto;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    padding: $card-padding-mobile;
    margin: $title-margin-mobile;

    &:after {
      margin-right: $horizontal-spacing-mobile;
    }
  }
}

.measuringWrapper {
  padding-top: $vertical-spacing;
  @media (max-width: $breakpoint-xsmall-max) {
    padding-top: $vertical-spacing-mobile;
  }
}
