@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.errorPage {
  display: flex;
  text-align: center;
  margin: auto;
  flex-direction: column;
  max-width: 600px;
  padding: 16px;

  .text {
    margin: 24px 0;
  }
  button {
    margin: 8px 0;
  }
}
