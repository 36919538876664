@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.top {
  @media (min-width: $breakpoint-small) {
    display: flex;
    align-items: flex-start;
    padding: 0 0 32px;
  }
}

.section {
  flex: 1 1 auto;
  @media (max-width: $breakpoint-xsmall-max) {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

.title {
  font-weight: 500;
  color: $tint-grey-78;
  margin-bottom: 20px;
  @media (max-width: $breakpoint-xsmall-max) {
    margin-bottom: 8px;
  }
}
.list {
  li {
    margin-bottom: 9px;
    @media (max-width: $breakpoint-xsmall-max) {
      margin-bottom: 6px;
    }
  }
}

.logo {
  display: none;

  img {
    width: 86px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
  }
}
