@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.formItem {
  color: $global-bodytext-color;
  &:focus-within {
    label {
      color: $brand-primary;
    }
  }

  .extraInfo {
    min-height: 18px;
  }

  .isInvalid {
    &:focus-within {
      label {
        color: $brand-primary;
      }
    }
  }
}
