@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.sidebarLink {
  display: flex;
  align-items: center;

  &:hover {
    .title {
      color: $brand-primary;
    }
    .imageWrapper {
      transform: scale(1.05);
    }
    .icon {
      transform: translateX(2px);
      svg path {
        fill: $brand-primary;
      }
    }
  }
}

.bigLink {
  height: 48px;
  display: flex;
  align-items: center;
}

.smallLink {
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    margin-right: 8px;
    transition: all 0.15s ease-in-out;
    svg path {
      fill: $global-bodytext-color;
    }
  }
}

.imageWrapper {
  height: 40px;
  min-height: 40px;
  width: 64px;
  min-width: 64px;
  overflow: hidden;
  border-radius: 6px;
  margin-right: 16px;
  transition: all 0.15s ease-in-out;
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  font-size: 14px;
  color: $global-bodytext-color;
  @include truncate-text(40, 2);
}
