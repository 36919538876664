@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.blogBodyText {
}

.extraInfo {
  display: flex;
  @include body-text-alignment;
}

.date {
  display: flex;
  margin-bottom: 24px;
  font-size: 14px;
  color: $global-subtext-color;
  @include body-text-alignment;
}

.aligner {
  @include body-text-size;
}
