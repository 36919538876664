@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.productSidebar {
  display: flex;
  flex-direction: column;

  .block:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media (max-width: 999px) {
  .productSidebar {
    flex-direction: row;
    margin: 24px -12px 0 -12px;

    .block {
      padding: 0 12px;
      width: 50%;
    }

    .block:not(:last-child) {
      margin-bottom: 0px;
    }
  }
}

@media (max-width: $breakpoint-xsmall-max) {
  .productSidebar {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 16px -8px 0 -8px;

    .block {
      padding: 0 8px;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}
