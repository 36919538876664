@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.extraInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  padding-top: 16px;
  margin-top: 32px;
  border-top: $global-border-style;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-top: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.author {
  display: flex;
  align-items: center;
}

.image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 24px 0 0;
  @media (max-width: $breakpoint-xsmall-max) {
    width: 46px;
    height: 46px;
    margin-right: 16px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.authorName {
  color: $global-bodytext-color;
}

.authorDescription {
  color: $global-subtext-color;
}

.sharing {
  @media (max-width: $breakpoint-xsmall-max) {
    margin-top: 16px;
  }
}
