@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.newsletter {
  position: relative;
  .container {
    width: 900px;
    margin: auto;
    @media (max-width: $breakpoint-small-max) {
      margin: 0 16px;
      width: auto;
    }
  }

  .foreground {
    position: relative;
    z-index: 1;
  }
  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .description {
    margin: 22px 0;
  }
  .image {
    margin-right: 32px;
    border-radius: $global-border-radius;
    overflow: hidden;
    box-shadow: $global-shadow-card-small;
    width: 200px;
    height: 274px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: $breakpoint-small-max) {
      display: none;
    }
  }
  .inner {
    flex: 1 1 50%;
  }
  .title {
    margin: 10px 0;
  }
  .wrap {
    iframe {
      width: 100% !important;
    }
    @media (min-width: $breakpoint-medium) {
      max-width: 90%;
    }
  }
}
