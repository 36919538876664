@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.checkboxContainer .checkboxWrapper {
  max-height: initial;

  ul {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  ul:not([class]) > li:nth-child(n + 2) {
    margin-top: 0;
  }
}

.showMore {
  font-family: $global-font-family;

  @include focus-custom();

  .showMoreText {
    color: $brand-primary;
  }

  .toggleIcon {
    svg path {
      fill: $brand-primary;
    }
  }
}
