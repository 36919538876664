@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
.downloadOverviewModule {
  display: flex;

  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
  }
}

.mobileSelect {
  display: none;
  @media (max-width: $breakpoint-xsmall-max) {
    display: flex;
    margin-bottom: 24px;
  }
}

.sidebar {
  margin-right: 40px;
  max-width: 380px;
  min-width: 280px;
  flex-shrink: 0;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-right: 0;
    max-width: 100%;
  }
}

.filterMenu {
  display: flex;

  @media (max-width: $breakpoint-xsmall-max) {
    display: none;
  }
}

.overview {
  flex-grow: 1;
}
