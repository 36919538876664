@import "src/shared/styles/variables.scss"; @import "src/shared/styles/placeholder.scss";
$white-checkmark-svg: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zNjg3IDAuNDY5NzI3TDEyLjQ2OTcgMS41ODA4OEw0LjY1MzE2IDkuNDY5NzNMMC40Njk2NjYgNS4yNDc1MkwxLjU3MDYzIDQuMTM2MzZMNC42NTMxNiA3LjI0NzQxTDExLjM2ODcgMC40Njk3MjdaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);

.checkbox {
  input.input {
    -webkit-appearance: none;
    background-color: white;
    border: $global-border-style;
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    display: inline-block;
    position: relative;
    margin: 0 8px 0 0;
    border-radius: 2px;
    cursor: pointer;
    top: 2px;

    &:checked {
      background-color: $brand-primary;
      border-color: $brand-primary;
      background-image: $white-checkmark-svg;
      background-position: center;
      background-size: 12px;
      background-repeat: no-repeat;
    }

    @include focus-custom();

    &:disabled {
      &,
      label.label {
        cursor: not-allowed;
      }

      border-color: $global-border-style;
      background-color: rgba(white, 0.8);

      &:checked {
        background-color: rgba($brand-primary, 0.3);
        border-color: rgba($brand-primary, 0.5);
      }
    }
  }
}
